import { useOktaAuth } from "@okta/okta-react";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { LynxJustificationModal } from "components/LynxComponents/LynxJustificationModal/LynxJustificationModal";
import { AuthModal } from "components/ReusableComponents/AuthModal/AuthModal";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { formikModelHelpers, formikModels, validationHelpers } from "validation";
import { StabilityFormMode } from "./StabilityFormDetails";

interface StabilityFormAuthModalProps {
    isModalOpen: boolean;
    mode: StabilityFormMode;
    setIsModalOpen: (value: boolean) => void;
}

const StabilityFormAuthModal = observer((props: StabilityFormAuthModalProps) => {
    const navigate = useNavigate();
    const { stabilityFormId } = useParams();
    const formik = useFormikContext<formikModels.StabilityFormFormikModel>();
    const { oktaAuth } = useOktaAuth();
    const { stabilityFormStore, identityStore } = useStore();
    const { isModalOpen, setIsModalOpen, mode } = { ...props };

    const isEditing = mode === StabilityFormMode.EDIT;
    const isNewVersion = mode === StabilityFormMode.NEW_VERSION;
    const [userMessage, setUserMessage] = useState("");
    const [isShowReAuthenticate, setIsShowReAuthenticate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [numberOfRetries, setNumberOfRetries] = useState(2);

    const handleResponse = async (stabilityFormId: string, statusCode: number, errors: ApiValidationError[]) => {
        switch (statusCode) {
            case 200:
            case 201:
                setNumberOfRetries(2);
                navigate(routes.stabilityFormDetails.to(stabilityFormId));
                break;
            case 401:
                setNumberOfRetries(numberOfRetries - 1);
                setErrorMessage(numberOfRetries + " login attempt remaining, please try again or contact an admin");
                if (numberOfRetries <= 0) {
                    setNumberOfRetries(2);
                    await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}${routes.login}` });
                }
                break;
            case 400:
                stabilityFormStore.setErrors(
                    validationHelpers.generateFormikErrorsFromApi<formikModels.StabilityFormFormikModel>(errors)
                );

                setIsShowReAuthenticate(false);
                formik.setSubmitting(false);
                break;
            default:
                setErrorMessage("Something went wrong. Please, try again");
                break;
        }
    };

    const submitStabilityForm = async (authModel: AuthModel) => {
        const apiInputModel = formikModelHelpers.convertToStabilityFormInputModel(formik.values);

        if (isEditing) {
            await stabilityFormStore.editStabilityForm(
                identityStore.currentCustomer.id,
                stabilityFormId!,
                apiInputModel,
                authModel,
                userMessage,
                handleResponse
            );
        } else if (isNewVersion) {
            await stabilityFormStore.createNewStabilityFormVersion(
                identityStore.currentCustomer.id,
                stabilityFormId!,
                apiInputModel,
                authModel,
                userMessage,
                handleResponse
            );
        } else {
            await stabilityFormStore.createStabilityForm(
                identityStore.currentCustomer.id,
                apiInputModel,
                authModel,
                userMessage,
                handleResponse
            );
        }
    };

    return (
        <>
            <LynxJustificationModal
                open={isModalOpen}
                title={"Confirm your actions by providing comments."}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                onConfirm={() => {
                    setIsModalOpen(false);
                    setIsShowReAuthenticate(true);
                }}
                value={userMessage}
                handleValueChange={setUserMessage}
            />
            <AuthModal
                isOpen={isShowReAuthenticate}
                onCancel={() => {
                    setIsShowReAuthenticate(false);
                }}
                onClose={() => {
                    setIsShowReAuthenticate(false);
                }}
                onSubmit={(authModel: AuthModel) => {
                    submitStabilityForm(authModel);
                }}
                errorMessage={errorMessage}
                loading={stabilityFormStore.progressFlags.loadingReAuthentication}
                disabled={stabilityFormStore.progressFlags.loadingReAuthentication}
                onBack={() => {
                    setIsShowReAuthenticate(false);
                    setIsModalOpen(true);
                }}
            />
            &nbsp;
        </>
    );
});

export default StabilityFormAuthModal;
