import { Grid } from "@material-ui/core";
import { CustomersDataWithSearch } from "api/models/userManagement/userManagementApi";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import { LynxSelectWithSearchProps } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearchProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { LocationInfo } from "models/thorEvents/eventModels";
import { useStore } from "store/StoreConfigs";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { eventCreationStyles } from "./EventCreationStyles";
import { formikModels, useLynxFormikContext } from "validation";

const SiteLocationSection = observer(() => {
    const { customerDataStore, identityStore } = useStore();
    const formik = useLynxFormikContext<formikModels.EventFormikModel>();
    const classes = eventCreationStyles();

    const getLocations = (value: string | undefined) => {
        const request: CustomersDataWithSearch = {
            searchValue: value,
            customerId: identityStore.currentCustomer.id,
            pageNumber: 1,
            pageSize: 5,
        };

        customerDataStore.loadCustomerLocations(request);
    };

    const handleSiteChange: LynxSelectWithSearchProps<LocationInfo, false, false, false>["onChange"] = (
        _event,
        value,
        _reason,
        _details
    ) => {
        formik.setValueOrEmptyStr("site.id", value?.id, true);
        formik.setValueOrEmptyStr("site.metadata.code", value?.code);
        formik.setValueOrEmptyStr("site.metadata.name", value?.name);
    };

    const siteValue = formik.values.site.id
        ? { id: formik.values.site.id, code: formik.values.site.metadata.code, name: formik.values.site.metadata.name }
        : null;

    return (
        <SectionCard>
            <LynxTypography variant="h2" className={classes.titleMargin}>
                Location Information
            </LynxTypography>
            <Grid container item lg={6} md={8} sm={12}>
                <Grid item xs={12} className={classes.autocompleteMargin}>
                    <LynxTypography variant="h3" color="neutral400" className={classes.subtitle}>
                        <LabelWithRequiredSymbol label="Site" />
                    </LynxTypography>

                    <LynxSelectWithSearch
                        value={siteValue}
                        disablePortal
                        forcePopupIcon
                        isSearchWithAutocomplete
                        search={getLocations}
                        placeholder="Search by Site ID or Site Name"
                        options={customerDataStore.locations}
                        loading={customerDataStore.progressFlags.loadLocations}
                        freeSolo={false}
                        multiple={false}
                        disableClearable={false}
                        triggerSearchOnClear
                        onChange={handleSiteChange}
                        inputProps={{
                            name: "site.id",
                            error: !!formik.errors?.site?.id,
                            assistiveText: formik.errors?.site?.id,
                        }}
                        disablePopupIconInteractions
                        getOptionLabel={(x) => [x.code, x.name].filter((x) => x).join(" ")}
                    />
                </Grid>

                <Grid container item spacing={4} className={classes.rowPadding}>
                    <Grid item xs={4}>
                        <LynxInputForm
                            readOnly
                            name="site.metadata.code"
                            label="Site ID"
                            placeholder={commonConstants.emptyValue}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <LynxInputForm
                            readOnly
                            name="site.metadata.name"
                            label="Site Name"
                            placeholder={commonConstants.emptyValue}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </SectionCard>
    );
});

export default SiteLocationSection;
