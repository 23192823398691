import clsx from "clsx";
import {
    LynxDateTimePicker,
    LynxDateTimePickerProps,
} from "components/LynxComponents/LynxDateTimePicker/LynxDateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { FieldHookConfig, useField } from "formik";
import { reusableFormsStyles } from "./ReusableFormsStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { dateInLocalTimezone, convertDateToSelectedTimezone, dateInUTC } from "helpers/timezoneHelper";

export interface LynxDateTimePickerFormProps extends LynxDateTimePickerProps {
    variant: "date" | "datetime";
    timezone: string;
    assistiveText?: string;
    assistiveTextClassName?: string;
    sizes?: "small" | "medium" | "large";
    customRequestFormat?: string;
    isDateOnly?: boolean;
}

// TODO: Investigate how to simplify DateTimePicker, without extra work-arounds in handleChange and getDate functions.
export const LynxDateTimePickerForm = (props: FieldHookConfig<string> & LynxDateTimePickerFormProps) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && !!meta.error;

    const {
        sizes = "medium",
        onChange: propsOnChange,
        label,
        labelClassName,
        variant,
        isDateOnly = false,
        timezone,
        ...rest
    } = { ...props };

    const { onChange, value, ...fieldRest } = { ...field };
    const classes = reusableFormsStyles({ size: props.sizes, hasError });

    const assistiveTextClasses = clsx(!hasError && classes.assistiveTextHidden, props.assistiveTextClassName);
    const labelClasses = clsx(classes.label, props.labelClassName);
    const inputClasses = clsx(
        hasError && classes.inputBorderErrorColor,
        classes.inputFullWidth,
        classes.inputPadding,
        classes.placeholderText,
        props.className
    );

    const handleChange = (value: Dayjs | null) => {
        if (value) {
            const dateWithSetMetadata = convertDateToSelectedTimezone(value, timezone, true);

            const formattedDate = dateInUTC(dateWithSetMetadata).format(
                props.customRequestFormat ?? (props.format as string)
            );

            helpers.setValue(formattedDate);
        }

        !!meta.error && helpers.setError(undefined);
    };

    const handleEnterPress = (event: any) => {
        const date = dateInLocalTimezone(event.target.value);

        if (event.key === "Enter" && !isNaN(date.valueOf())) {
            handleChange(date);
        }
    };

    const getDate = (date: string) => {
        if (!date) return undefined;

        // Known issues that aren`t fixed yet in dayjs, because of them we have to use work-arounds.
        // https://github.com/iamkun/dayjs/issues/1227
        // https://github.com/iamkun/dayjs/issues/651
        if (isDateOnly) {
            const usersTzOffset = dayjs().tz(dayjs.tz.guess()).utcOffset();

            // leaving this logic as a fix
            return dateInUTC(date, usersTzOffset > 0);
        }

        const convertedDate = convertDateToSelectedTimezone(date, timezone);

        // converting UTC time to timezone with offset +00 make datepicker show incorrect value
        // leaving this logic as a fix
        return convertedDate.isUTC() ? dateInUTC(date) : convertedDate;
    };

    return (
        <>
            <LynxDateTimePicker
                onKeyDown={handleEnterPress}
                value={getDate(value)}
                onChange={propsOnChange ?? handleChange}
                className={inputClasses}
                label={label}
                labelClassName={labelClasses}
                {...rest}
                {...fieldRest}
            />
            <LynxTypography
                variant="body-s"
                color={hasError ? "critical500" : "neutral400"}
                className={assistiveTextClasses}
            >
                {props.assistiveText ?? (meta.error || "Error")}
            </LynxTypography>
        </>
    );
};
