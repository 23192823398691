import { ButtonBase, MenuItem, Popover } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import clsx from "clsx";
import { LynxBreadcrumbs } from "components/Breadcrumbs/LynxBreadcrumbs";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import { getPermissionKey } from "helpers/permissionHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { UserType } from "models/userManagement/userManagementModels";
import { useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { lynxHeaderStyles } from "./LynxHeaderStyles";
import { withFixed } from "helpers/withFixed";
import { ScrollWatcher } from "components/ReusableComponents/ScrollWatcher";

const ProfileSection = observer(() => {
    const classes = lynxHeaderStyles();
    const { oktaAuth } = useOktaAuth();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { identityStore } = useStore();
    const { firstName, lastName } = identityStore.currentUser;

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);

    const handleLogout = async () => {
        try {
            await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
        } catch (error) {
            console.log("Error signing out", error);
        }
    };

    return (
        <>
            <ButtonBase className={classes.profileButton} onClick={handleOpen}>
                <LynxTypography>
                    <Span color="neutral400">Hi,&nbsp;</Span>
                    {firstName} {lastName}
                </LynxTypography>
                <LynxAvatar firstName={firstName} lastName={lastName} />
            </ButtonBase>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                disableScrollLock
                classes={{ paper: classes.profilePopover }}
                // connect top-right point of the popup to bottom-right point of the anchor and add vertical margin of 2rem
                transformOrigin={{
                    vertical: -32,
                    horizontal: "right",
                }}
            >
                <div className={classes.popoverFirstSection}>
                    <LynxAvatar firstName={firstName} lastName={lastName} size={2.5} fontSize={0.875} />
                    <div className={classes.popoverEmailAndName}>
                        <Span variant="body-medium">
                            {firstName} {lastName}
                        </Span>
                        <Span color="neutral400" variant="body-s">
                            {identityStore.currentUser.id}
                        </Span>
                    </div>
                </div>
                <LynxButton
                    leftIcon={<LynxIcon name="logout" />}
                    variant="tertiary"
                    className={classes.logoutButton}
                    onClick={handleLogout}
                >
                    Log Out
                </LynxButton>
            </Popover>
        </>
    );
});

export const secondHeaderPortalId = "second-header-portal";
export const thirdHeaderPortalId = "third-header-portal";

export const LynxHeader = observer(() => {
    const classes = lynxHeaderStyles();
    const { identityStore, permissionsStore } = useStore();
    const [bodyPaddingRight, setBodyPaddingRight] = useState<string>("");

    const userManagementPermissions = [
        getPermissionKey(actions.system.customers.view),
        getPermissionKey(actions.system.customers.manage),
        getPermissionKey(actions.system.users.view),
        getPermissionKey(actions.system.users.manage),
        getPermissionKey(actions.system.groups.view),
        getPermissionKey(actions.system.groups.manage),
    ];

    const isUserHasPermission = permissionsStore.hasPermission(...userManagementPermissions);

    const [isFixed, setIsFixed] = useState(false);

    const handleCustomerChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        identityStore.setCurrentCustomer(e.target.value as string);
        window.location.reload();
    };

    /*
        this code is needed to handle dynamic padding that MUI adds to body tag when user opens menus and dropdowns in Chrome 
        because scrollbar in Chrome affects the width of the page.
        Fixed header doesn't take this padding into account because it has position: fixed
        this causes jumping of fixed header content left and right when user opens/closes dropdowns
        in order to prevent this jumping we listen to changes of style tag on body and dynamically add the same padding to fixed header
    */
    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            setBodyPaddingRight(document.body.style.paddingRight);
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ["style"] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const renderMainHeader = (isFixed: boolean) => {
        return (
            <div className={withFixed(classes.mainHeader, isFixed)}>
                <LynxBreadcrumbs />
                <div className={classes.mainHeaderRightSide}>
                    {identityStore.currentUser.type === UserType.SystemUser && (
                        <LynxSelect
                            className={classes.customerDropdown}
                            value={identityStore.currentCustomer.id}
                            multiple={false}
                            onChange={handleCustomerChange}
                        >
                            {identityStore.customers.map((x) => (
                                <MenuItem
                                    key={x.id}
                                    value={x.id}
                                    disabled={x.id === commonConstants.system && !isUserHasPermission}
                                >
                                    {x.name}
                                </MenuItem>
                            ))}
                        </LynxSelect>
                    )}
                    <ProfileSection />
                </div>
            </div>
        );
    };

    return (
        <header>
            <ScrollWatcher id="first-header" callback={setIsFixed} topMargin={16} />
            {renderMainHeader(false)}
            <div
                className={clsx(isFixed && classes.fixedHeadersContainer)}
                style={bodyPaddingRight ? { paddingRight: bodyPaddingRight } : undefined}
            >
                <div className={clsx(!isFixed && classes.displayNone)}>{renderMainHeader(true)}</div>

                <div id={secondHeaderPortalId} />
                <div id={thirdHeaderPortalId} />
            </div>
        </header>
    );
});
