import { EventStatus } from "models/thorEvents/eventModels";

type EventStatusObject = { eventStatus: EventStatus } | { status: EventStatus } | EventStatus;

export const eventIsClosed = (statusObject: EventStatusObject) => {
    const status =
        typeof statusObject === "number"
            ? statusObject
            : "eventStatus" in statusObject
            ? statusObject.eventStatus
            : statusObject.status;

    return status === EventStatus.Closed || status === EventStatus.AutoReleased;
};
