import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ListCustomerGroupsRequest, ListSystemGroupsRequest } from "api/models/userManagement/userManagementApi";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxSearch } from "components/LynxComponents/LynxSearch/LynxSearch";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import FiltersOrSearchResultEmptyState from "components/ReusableComponents/FiltersOrSearchResultEmptyState";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { noSearchResultMessage } from "lynxConstants";
import { observer } from "mobx-react";
import { PaginationArea } from "models/shared/Page";
import { actions } from "models/userManagement/actions";
import { GroupListItem, GroupStatus, MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import { userManagementStyles } from "../tabs/userManagementStyles";
import UserActions, { UserActionOption } from "../UserActions";

export const GroupList = observer(() => {
    const classes = userManagementStyles();
    const { groupStore, identityStore, permissionsStore, commonStore, userStore } = useStore();

    const navigate = useNavigate();

    const editGroupPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.manage)
        : permissionsStore.getPermissionResult(actions.customer.groups.manage, identityStore.currentCustomer.id);

    const viewGroupPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.view)
        : permissionsStore.getPermissionResult(actions.customer.groups.view, identityStore.currentCustomer.id);

    const viewUserPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.view)
        : permissionsStore.getPermissionResult(actions.customer.users.view, identityStore.currentCustomer.id);

    const loadGroups = () => {
        const request: ListCustomerGroupsRequest | ListSystemGroupsRequest = {
            pageNumber: groupStore.currentPage,
            pageSize: groupStore.pageSize,
            searchValue: groupStore.searchInputTrimmed,
            searchParameter: groupStore.searchParameter,
        };
        groupStore.loadGroups(request);
    };

    useEffect(() => {
        loadGroups();
        if (viewUserPermission.status === MarvelPermissionStatus.Allow) {
            userStore.loadUsers();
        }
    }, [groupStore.currentPage, groupStore.pageSize]);

    const getGroupActions = (group: GroupListItem) => {
        const actions: UserActionOption[] = [
            {
                optionName: "View / Edit Details",
                action: () => navigate(`../${group.id}`),
            },
        ];

        if (editGroupPermission.status === MarvelPermissionStatus.Allow && !group.readonlyStatus) {
            actions.push({
                optionName: group.status === "Active" ? "Deactivate" : "Activate",
                action: () =>
                    groupStore.changeGroupStatus(
                        group.id,
                        group.status === GroupStatus.Active ? GroupStatus.Deactivated : GroupStatus.Active
                    ),
            });
        }

        if (viewGroupPermission.status === MarvelPermissionStatus.Allow) {
            actions.push({
                optionName: "Audit Trail",
                action: () => navigate(`/userManagement/groups/${group.id}/audit`),
                withImmediateAction: true,
            });
        }

        return actions;
    };

    const groupRow = (group: GroupListItem) => {
        return (
            <>
                {/* <TableCell>
                    <LynxTypography className={classes.bolderText}>{group.id}</LynxTypography>
                </TableCell> */}
                <TableCell>
                    <LynxTypography className={classes.bolderText}>{group.name}</LynxTypography>
                </TableCell>
                <TableCell>
                    <LynxTypography className={classes.groupRoleName}>{group.role?.name}</LynxTypography>
                </TableCell>
                <TableCell>
                    <LynxTypography className={classes.tableText}>{group.status}</LynxTypography>
                </TableCell>
                <TableCell>
                    <UserActions options={getGroupActions(group)} />
                </TableCell>
            </>
        );
    };

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.tabContainer}>
                <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
                    <Grid item>
                        <LynxSearch
                            searchValue={groupStore.searchValue}
                            setSearchValue={groupStore.setSearchValue}
                            request={loadGroups}
                            placeholder={"Search Group"}
                            triggerSearchOnClear
                        />
                    </Grid>
                    <Grid item>
                        <LynxButton
                            size="medium"
                            className={classes.actionButton}
                            onClick={() => navigate("../create")}
                            disabled={editGroupPermission.status !== MarvelPermissionStatus.Allow}
                            loading={editGroupPermission.status === MarvelPermissionStatus.Loading}
                        >
                            Add New User Group
                        </LynxButton>
                    </Grid>
                </Grid>

                {groupStore.progressFlags.loadingGroups ? (
                    <FullScreenLoadingIndicator />
                ) : (
                    <>
                        {groupStore.groups.length === 0 ? (
                            <FiltersOrSearchResultEmptyState message={noSearchResultMessage} />
                        ) : (
                            <>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow style={{ borderBottom: "none" }}>
                                            {/* <TableCell className={classes.headerCell}>Id</TableCell> */}
                                            <TableCell className={classes.headerCell}>Name</TableCell>

                                            <TableCell className={classes.headerCell}>Role</TableCell>
                                            <TableCell className={classes.headerCell}>Status</TableCell>
                                            <TableCell className={classes.headerCell} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupStore.groups.map((group) => (
                                            <TableRow key={group.id} className={classes.tableRow}>
                                                {/* {group.isDeleted ? deletedGroupRow() : groupRow(group)} */}
                                                {groupRow(group)}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                                <ThorPagination
                                    page={groupStore.currentPage}
                                    pages={groupStore.totalPages}
                                    onPageChange={groupStore.movePage}
                                    isLastPage={groupStore.isLastPage}
                                    setPage={groupStore.setPage}
                                    area={PaginationArea.Groups}
                                    setPageSize={groupStore.setPageSize}
                                />
                            </>
                        )}
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
