import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { lynxBreadcrumbsStyles } from "./LynxBreadcrumbsStyles";

const EventBreadcrumb = observer(() => {
    const { thorEventViewStore, auditStore } = useStore();
    const flags = thorEventViewStore.progressFlags;
    const loading =
        flags.loadManualEventInformation ||
        flags.loadingEventDetails ||
        flags.loadingEventShortInfo ||
        auditStore.progressFlags.loadingAuditEntityShortInfo;

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {thorEventViewStore.eventDetails.displayId ||
                auditStore.auditEntityShortInfo.displayId ||
                commonConstants.emptyValue}
        </>
    );
});

const GroupBreadcrumb = observer(() => {
    const { groupStore, auditStore } = useStore();

    return (
        <>
            {groupStore.progressFlags.loadingGroupDetails || auditStore.progressFlags.loadingAuditEntityShortInfo
                ? "Loading..."
                : groupStore.selectedGroup.name
                ? groupStore.selectedGroup.name
                : auditStore.auditEntityShortInfo.displayId
                ? auditStore.auditEntityShortInfo.displayId
                : commonConstants.emptyValue}
        </>
    );
});

const StabilityFormBreadcrumb = observer(() => {
    const { stabilityFormStore, auditStore } = useStore();

    return (
        <>
            {stabilityFormStore.progressFlags.loadingStabilityForm ||
            auditStore.progressFlags.loadingAuditEntityShortInfo
                ? "Loading..."
                : stabilityFormStore.stabilityFormDetails?.number
                ? stabilityFormStore.stabilityFormDetails.number
                : stabilityFormStore.stabilityFormToUpdate?.number
                ? stabilityFormStore.stabilityFormToUpdate.number
                : auditStore.auditEntityShortInfo.displayId}
        </>
    );
});

const auditTrail = "Audit Trail";
const edit = "Edit";

const breadcrumbRoutes: BreadcrumbsRoute[] = [
    { path: routes.root, breadcrumb: null },
    { path: routes.events, breadcrumb: "Excursion Impact Evaluation" },
    { path: routes.eventsCreate, breadcrumb: "New Event" },
    { path: routes.eventDetails.path, breadcrumb: EventBreadcrumb },
    { path: routes.eventsEdit.path, breadcrumb: edit },
    { path: routes.eventsAudit.path, breadcrumb: auditTrail },
    { path: routes.stabilityForms, breadcrumb: "Stability Data Management" },
    { path: "/stabilityForms/:stabilityFormId/audit", breadcrumb: auditTrail },
    { path: routes.stabilityFormsCreate, breadcrumb: "New Stability Form" },
    { path: routes.stabilityFormDetails.path, breadcrumb: StabilityFormBreadcrumb },
    { path: routes.stabilityFormsEdit.path, breadcrumb: edit },
    { path: routes.userManagement, breadcrumb: "User Management" },
    { path: routes.stabilityFormNewVersion.path, breadcrumb: "New Version" },
    { path: "/userManagement/users/:userId", breadcrumb: null },
    { path: "/userManagement/users/:userId/audit", breadcrumb: auditTrail },
    { path: "/userManagement/groups/create", breadcrumb: "New User Group" },
    { path: "/userManagement/groups/:groupId", breadcrumb: GroupBreadcrumb },
    { path: "/userManagement/groups/:groupId/audit", breadcrumb: auditTrail },
    { path: "/userManagement/customers/:customerId", breadcrumb: null },
    { path: "/userManagement/customers/:customerId/audit", breadcrumb: auditTrail },
    { path: routes.pageNotFound, breadcrumb: "Page Not Found" },
];

export const LynxBreadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs(breadcrumbRoutes);
    const classes = lynxBreadcrumbsStyles();

    return (
        <Breadcrumbs
            classes={{ separator: classes.separatorIcon }}
            separator={<LynxIcon name="angleSmallRight" />}
            aria-label="breadcrumbs"
        >
            {breadcrumbs.map(({ match, breadcrumb, location }) => {
                const active = match.pathname === location.pathname;

                return (
                    <NavLink
                        key={match.pathname}
                        className={clsx(classes.link, { [classes.activeLink]: active })}
                        to={match.pathname}
                        onClick={(e) => active && e.preventDefault()}
                        tabIndex={active ? -1 : 0}
                    >
                        <LynxTypography
                            color={active ? "neutral600" : "neutral400"}
                            variant={active ? "body-sbold" : "body"}
                            component={"span"}
                        >
                            {breadcrumb}
                        </LynxTypography>
                    </NavLink>
                );
            })}
        </Breadcrumbs>
    );
};
