import { Box, Grid, MenuItem } from "@material-ui/core";
import { SetEventCodesRequest, SetEventPriorityRequest, SetEventTimezoneRequest } from "api/models/events/eventsApi";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import { getPermissionKey } from "helpers/permissionHelpers";
import { observer } from "mobx-react-lite";

import { LynxDateTimePicker } from "components/LynxComponents/LynxDateTimePicker/LynxDateTimePicker";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import { LynxSelectWithSearchProps } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearchProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import dayjs, { Dayjs } from "dayjs";
import { dateInLocalTimezone, dateInUTC } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants, promptTooltipMessages } from "lynxConstants";
import { AssessmentStatus, EventCode, EventProcessType, EventStatus, EventType } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { RangePickerProps } from "rc-picker/lib/RangePicker";
import { useEffect, useState } from "react";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { useStore } from "store/StoreConfigs";
import { eventLevelInformationStyles } from "./EventLevelInformationStyles";
import { eventIsClosed } from "helpers/eventIsClosed";

export default observer(function EventLevelInformation() {
    const classes = eventLevelInformationStyles();
    const { thorEventViewStore, identityStore, permissionsStore, customerDataStore, commonStore } = useStore();
    const event = thorEventViewStore.eventDetails;

    const [buttonText, setButtonAction] = useState("");

    const labelStyle = "abbrev";
    const { options, parseTimezone } = useTimezoneSelect({
        labelStyle,
        timezones: { ...allTimezones },
    });

    const assignPermissionKey = getPermissionKey(actions.customer.tor.events.assign, identityStore.currentCustomer.id);
    const editInformationPermissionKey = getPermissionKey(actions.customer.tor.events.editInformation, event.id);

    const assignPermissionStatus = permissionsStore.getPermissionStatus(assignPermissionKey);
    const editInformationPermissionStatus = permissionsStore.getPermissionStatus(editInformationPermissionKey);

    useEffect(() => {
        if (event.status === EventStatus.New || (event.status === EventStatus.PendingApproval && !event.qaUserId)) {
            setButtonAction("Assign");
        } else if (event.status === EventStatus.PendingReview || event.status === EventStatus.PendingApproval) {
            setButtonAction("Re-Assign");
        } else {
            setButtonAction("THIS SHOULD NOT BE VISIBLE");
        }
    }, [event.status, event.qaUserId]);

    const isButtonDisabled = () => {
        if (["Assign", "Re-Assign"].includes(buttonText)) {
            return assignPermissionStatus !== MarvelPermissionStatus.Allow;
        } else {
            return true;
        }
    };

    const showAssignToMeButton = () => {
        if (event.status === EventStatus.New) {
            return true;
        } else if (
            event.status === EventStatus.PendingReview &&
            event.reviewerUserId !== identityStore.currentUser.id
        ) {
            return event.assessments.every((x) => x.status === AssessmentStatus.PendingReview);
        } else if (event.status === EventStatus.PendingApproval && event.qaUserId !== identityStore.currentUser.id) {
            return event.assessments.every((x) => x.status === AssessmentStatus.PendingDecision);
        }

        return false;
    };

    const assignToMeButtonText = () => {
        if (
            event.status === EventStatus.New ||
            (event.status === EventStatus.PendingReview && !event.reviewerUserId) ||
            (event.status === EventStatus.PendingApproval && !event.qaUserId)
        ) {
            return "Assign to me";
        } else {
            return "Re-Assign to me";
        }
    };

    const isButtonLoading = () => {
        return (
            assignPermissionStatus === MarvelPermissionStatus.Loading ||
            thorEventViewStore.progressFlags.assigningUserToEvent
        );
    };

    const isEditingDisabled = (loading?: boolean) => {
        return editInformationPermissionStatus !== MarvelPermissionStatus.Allow || eventIsClosed(event) || loading;
    };

    const isEditingLoading = (loading: boolean) => {
        return editInformationPermissionStatus === MarvelPermissionStatus.Loading || loading;
    };

    const onDateChange = (changeFunc: Function, update: Date) => {
        if (!update) {
            return;
        }

        const dayJsDate = dateInLocalTimezone(update).format(commonConstants.shortDateFormat);

        const request = {
            customerId: identityStore.currentCustomer.id,
            eventId: event.id,
            date: dayJsDate,
        };

        changeFunc(request);
    };

    const handlePriorityChange = (e: any) => {
        const request: SetEventPriorityRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: event.id,
            priorityId: e.target.value,
        };

        thorEventViewStore.setEventPriority(request);
    };

    const handleCategoriesChange: LynxSelectWithSearchProps<EventCode, true, false, false>["onChange"] = (
        event,
        value,
        _reason,
        _details
    ) => {
        if (
            event.nativeEvent instanceof KeyboardEvent &&
            (event.nativeEvent.key === "Enter" || event.nativeEvent.key === "Backspace")
        ) {
            // handle select/unselect by enter/backspace.
            return;
        }

        if (!thorEventViewStore.progressFlags.settingCodes) {
            const request: SetEventCodesRequest = {
                eventId: thorEventViewStore.eventDetails.id,
                eventCodesIds: value.map((x) => x.id),
                customerId: identityStore.currentCustomer.id,
            };

            thorEventViewStore.setEventCodes(request);
        }
    };

    const handleTimezoneChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const request: SetEventTimezoneRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: event.id,
            timezone: parseTimezone(e.target.value as string).value,
        };
        thorEventViewStore.setEventTimezone(request);
    };

    const showButton = () => {
        return (
            event.status === EventStatus.New ||
            event.assessments.every((x) => x.status === AssessmentStatus.PendingReview) ||
            event.assessments.every((x) => x.status === AssessmentStatus.PendingDecision)
        );
    };

    const buttonAction = () => {
        thorEventViewStore.setAssignMenuOpen(true);
    };

    const scrollToCommentsAndAttachments = () => {
        const commentsSection = document.getElementById("event_comments_and_attachments_section");
        commentsSection?.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const disabledDate: RangePickerProps<Dayjs>["disabledDate"] = (current: Dayjs) => {
        // Can not select days before today
        return current && current < dayjs().startOf("day");
    };

    const getDate = (date: string | Date | null) => {
        if (date) {
            return dateInUTC(date);
        }
        return undefined;
    };

    const getTooltipMessage = (isForButton: boolean) => {
        const details = thorEventViewStore.eventDetails;
        const isCurrentUserIsReviever = details.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = details.qaUserId === identityStore.currentUser.id;

        if (isForButton) {
            return assignPermissionStatus !== MarvelPermissionStatus.Allow
                ? promptTooltipMessages.accessDenied
                : promptTooltipMessages.empty;
        } else {
            switch (true) {
                case eventIsClosed(details):
                    return promptTooltipMessages.event.decisionCompleted;
                case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                    return promptTooltipMessages.event.assignToYourself;
                case editInformationPermissionStatus !== MarvelPermissionStatus.Allow:
                    return promptTooltipMessages.accessDenied;
                default:
                    return promptTooltipMessages.empty;
            }
        }
    };

    return (
        <>
            <Grid container item direction="column" className={classes.gridItemMarginBottom} spacing={1}>
                <Grid item>
                    <InfoSubheader text="assigned to" icon={<LynxIcon name="users" />} />
                </Grid>
                {(event.reviewerUserId || event.qaUserId) && (
                    <Grid item className={classes.assignedUsersInfo}>
                        {event.reviewerUserId && (
                            <Box display="flex" gridGap="0.625rem" alignItems="center" className={classes.userInfo}>
                                {event.reviewerUserId !== commonConstants.system && (
                                    <LynxAvatarWithTooltip
                                        email={event.reviewerUserId}
                                        firstName={event.reviewerFirstName}
                                        lastName={event.reviewerLastName}
                                    />
                                )}
                                <Box display="flex" flexDirection="column">
                                    <LynxTypography variant="body-medium" color="neutral600">
                                        {event.reviewerUserId === commonConstants.system
                                            ? commonConstants.systemPascalCase
                                            : `${event.reviewerFirstName} ${event.reviewerLastName}`}
                                    </LynxTypography>
                                    <LynxTypography variant="body-s" color="neutral400">
                                        Reviewer
                                    </LynxTypography>
                                </Box>
                            </Box>
                        )}
                        {event.qaUserId && (
                            <Box display="flex" gridGap="0.625rem" alignItems="center" className={classes.userInfo}>
                                {event.qaUserId !== commonConstants.system && (
                                    <LynxAvatarWithTooltip
                                        email={event.qaUserId}
                                        firstName={event.qaFirstName}
                                        lastName={event.qaLastName}
                                    />
                                )}
                                <Box display="flex" flexDirection="column">
                                    <LynxTypography variant="body-medium" color="neutral600">
                                        {event.qaUserId === commonConstants.system
                                            ? commonConstants.systemPascalCase
                                            : `${event.qaFirstName} ${event.qaLastName}`}
                                    </LynxTypography>
                                    <LynxTypography variant="body-s" color="neutral400">
                                        QA
                                    </LynxTypography>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                )}
                <Grid item>
                    <Box display="flex" gridGap="0.5rem">
                        {showButton() && (
                            <PromptTooltip placement="top" title={getTooltipMessage(true)}>
                                <LynxButton
                                    variant={buttonText === "Re-Assign" ? "secondary" : "primary"}
                                    onClick={buttonAction}
                                    disabled={isButtonDisabled()}
                                    loading={isButtonLoading()}
                                >
                                    {buttonText}
                                </LynxButton>
                            </PromptTooltip>
                        )}
                        {showAssignToMeButton() && (
                            <PromptTooltip placement="top" title={getTooltipMessage(true)}>
                                <LynxButton
                                    variant="secondary"
                                    onClick={() => {
                                        thorEventViewStore.assignUserToEvent({
                                            userId: identityStore.currentUser.id,
                                            // 1 == Reviewer 2 == QA
                                            assignUserType:
                                                event.status === EventStatus.PendingReview ||
                                                event.status === EventStatus.New
                                                    ? 1
                                                    : 2,
                                            eventId: event.id,
                                            customerId: identityStore.currentCustomer.id,
                                            comment: "",
                                            notShowEmptyContent: true,
                                        });
                                    }}
                                    disabled={isButtonDisabled()}
                                    loading={isButtonLoading()}
                                >
                                    {assignToMeButtonText()}
                                </LynxButton>
                            </PromptTooltip>
                        )}
                    </Box>
                </Grid>
            </Grid>

            <Grid container item direction="column" spacing={1} className={classes.gridItemMarginBottom}>
                <Grid item xs={12}>
                    <InfoSubheader text="priority" icon={<LynxIcon name="flag" />} />
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item lg={6} md={10} sm={10}>
                        <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                            <div>
                                <LynxSelect
                                    labelId="type-of-event-label"
                                    disabled={isEditingDisabled(thorEventViewStore.progressFlags.settingPriority)}
                                    loading={isEditingLoading(customerDataStore.progressFlags.loadingPriorities)}
                                    value={event.priorityId}
                                    onChange={handlePriorityChange}
                                    label="Priority"
                                    className={classes.selectMediumPaddingAndWidth}
                                >
                                    {customerDataStore.eventPriorities?.map((x) => (
                                        <MenuItem value={x.id} key={x.id}>
                                            <LynxTypography className={classes.selectTypographyWithIcon}>
                                                <LynxIcon name="flag" style={{ fill: x.color }} /> {x.name}
                                            </LynxTypography>
                                        </MenuItem>
                                    ))}
                                </LynxSelect>
                            </div>
                        </PromptTooltip>
                    </Grid>
                    <Grid item lg={6} md={10} sm={10}>
                        <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                            <div>
                                <LynxDateTimePicker
                                    disabled={isEditingDisabled(thorEventViewStore.progressFlags.settingDueDate)}
                                    loading={isEditingLoading(thorEventViewStore.progressFlags.settingDueDate)}
                                    value={getDate(event.dueDate)}
                                    format={commonConstants.shortDateFormat}
                                    onChange={(update: any) => {
                                        onDateChange(thorEventViewStore.setDueDate, update);
                                    }}
                                    placeholder="Select date..."
                                    className={clsx(classes.picker, classes.inputPadding)}
                                    label="Assessment Due Date"
                                    disabledDate={disabledDate}
                                    showToday={false}
                                />
                            </div>
                        </PromptTooltip>
                    </Grid>
                    {event.type === EventType.Transportation && (
                        <>
                            <Grid item lg={6} md={10} sm={10}>
                                <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                                    <div>
                                        <LynxDateTimePicker
                                            className={clsx(classes.picker, classes.inputPadding)}
                                            disabled={isEditingDisabled(
                                                thorEventViewStore.progressFlags.settingShippingDate
                                            )}
                                            loading={isEditingLoading(
                                                thorEventViewStore.progressFlags.settingShippingDate
                                            )}
                                            value={getDate(event.shippingDate)}
                                            onChange={(update: any) =>
                                                onDateChange(thorEventViewStore.setShippingDate, update)
                                            }
                                            placeholder="Select date..."
                                            label="Shipping Date"
                                            format={commonConstants.shortDateFormat}
                                            showToday={false}
                                        />
                                    </div>
                                </PromptTooltip>
                            </Grid>
                            <Grid item lg={6} md={10} sm={10}>
                                <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                                    <div>
                                        <LynxDateTimePicker
                                            className={clsx(classes.picker, classes.inputPadding)}
                                            value={getDate(event.deliveryDate)}
                                            disabled={isEditingDisabled(
                                                thorEventViewStore.progressFlags.settingDeliveryDate
                                            )}
                                            loading={isEditingLoading(
                                                thorEventViewStore.progressFlags.settingDeliveryDate
                                            )}
                                            onChange={(update: any) =>
                                                onDateChange(thorEventViewStore.setDeliveryDate, update)
                                            }
                                            placeholder="Select date..."
                                            label="Delivery Date"
                                            format={commonConstants.shortDateFormat}
                                            showToday={false}
                                        />
                                    </div>
                                </PromptTooltip>
                            </Grid>
                        </>
                    )}
                    <Grid item lg={6} md={10} sm={10}>
                        <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                            <div>
                                <LynxDateTimePicker
                                    disabled={isEditingDisabled(thorEventViewStore.progressFlags.settingQuarantineDate)}
                                    loading={isEditingLoading(thorEventViewStore.progressFlags.settingQuarantineDate)}
                                    value={getDate(event.quarantineDate)}
                                    format={commonConstants.shortDateFormat}
                                    onChange={(update: any) => {
                                        onDateChange(thorEventViewStore.setQuarantineDate, update);
                                    }}
                                    placeholder="Select date..."
                                    className={clsx(classes.picker, classes.inputPadding)}
                                    label="Quarantine Date"
                                    showToday={false}
                                />
                            </div>
                        </PromptTooltip>
                    </Grid>
                </Grid>

                <Grid container item className={classes.timezoneContainer}>
                    <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                        <div className={classes.timezoneSelectFullWidth}>
                            <LynxSelect
                                className={classes.timezoneSelectFullWidth}
                                label="Event Time Zone"
                                disabled={isEditingDisabled(thorEventViewStore.progressFlags.settingTimezone)}
                                loading={isEditingLoading(thorEventViewStore.progressFlags.settingTimezone)}
                                value={parseTimezone(thorEventViewStore.eventDetails.timezone).value}
                                onChange={handleTimezoneChange}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </LynxSelect>
                        </div>
                    </PromptTooltip>
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.gridItemMarginBottom}>
                <Grid item xs={12}>
                    <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                        <div>
                            <InfoSubheader text="event codes" icon={<LynxIcon name="eventCode" />} />
                        </div>
                    </PromptTooltip>
                </Grid>

                <Grid item xs={12}>
                    <PromptTooltip placement="top" title={getTooltipMessage(false)}>
                        <div>
                            <LynxSelectWithSearch
                                isSearchWithAutocomplete={false}
                                disablePortal
                                disabled={
                                    editInformationPermissionStatus !== MarvelPermissionStatus.Allow ||
                                    eventIsClosed(event)
                                }
                                loading={
                                    customerDataStore.progressFlags.loadingEventCodes ||
                                    thorEventViewStore.progressFlags.settingCodes
                                }
                                placeholder="Add Event Code"
                                multiple
                                disableCloseOnSelect
                                withTags
                                onChange={handleCategoriesChange}
                                value={customerDataStore.eventCodes.filter((x) =>
                                    thorEventViewStore.eventDetails.eventCodeIds.some((y) => x.id === y)
                                )}
                                freeSolo={false}
                                disableClearable={false}
                                options={customerDataStore.eventCodes}
                                getOptionLabel={(x) => x.name}
                                getOptionId={(x) => x.id}
                            />
                        </div>
                    </PromptTooltip>
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.gridItemMarginBottom}>
                <Grid item xs={12}>
                    <InfoSubheader text="event categories" icon={<LynxIcon name="eventCategories" />} />
                </Grid>

                <Grid item className={classes.eventCategoryChip}>
                    {EventType[thorEventViewStore.eventDetails.type]}
                </Grid>
                <Grid item className={classes.eventCategoryChip}>
                    {EventProcessType[thorEventViewStore.eventDetails.processType]}
                </Grid>
            </Grid>

            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <InfoSubheader
                        text={`comments (${commonStore.commentsTotalCount})`}
                        icon={<LynxIcon name="comments" />}
                    />
                </Grid>
                <Grid item className={classes.gridItemMarginBottom}>
                    <LynxButton
                        onClick={scrollToCommentsAndAttachments}
                        className={classes.textWithIcon}
                        variant="tertiary"
                        leftIcon={<LynxIcon name="arrowUpRight" />}
                    >
                        Go to Comments
                    </LynxButton>
                </Grid>

                <Grid item>
                    <InfoSubheader
                        text={`attachments (${thorEventViewStore.attachmentsTotalCount})`}
                        icon={<LynxIcon name="attachments" />}
                    />
                </Grid>
                <Grid item>
                    <LynxButton
                        onClick={scrollToCommentsAndAttachments}
                        className={classes.textWithIcon}
                        variant="tertiary"
                        leftIcon={<LynxIcon name="arrowUpRight" />}
                    >
                        Go to Attachments
                    </LynxButton>
                </Grid>
            </Grid>
        </>
    );
});
