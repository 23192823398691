import { NodeProps } from "@xyflow/react";
import { batchDistributionDiagramPageStyles, getGreyedOutClass } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxDeliveryNode } from "./BatchDistributionDiagramTypes";
import { DummyNodeHandle } from "./DummyNodeHandle";
import { EventCard } from "./EventCard";
import clsx from "clsx";
import { diagramFormatDate } from "./batchDistributionDiagramHelpers";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { eventIsClosed } from "helpers/eventIsClosed";

export const DeliveryNode = (props: NodeProps<LynxDeliveryNode>) => {
    const classes = batchDistributionDiagramPageStyles();

    return (
        <>
            <div className={clsx(classes.deliveryNode, getGreyedOutClass(props.data))}>
                <LynxTypography variant="body-xs">
                    Delivery {props.data.deliveryNumber}
                    {props.data.events.length > 0 &&
                        props.data.events.every(
                            (event) => event.isWithFirstEconomicCustomer === true && eventIsClosed(event)
                        ) && (
                            <Span
                                variant="body-xs"
                                className={clsx(classes.isWithCustomer, getGreyedOutClass(props.data))}
                            >
                                To Customer
                            </Span>
                        )}
                </LynxTypography>
                <LynxTypography variant="body-xs" color="neutral400">
                    {diagramFormatDate(props.data.shippingDate)} — {diagramFormatDate(props.data.deliveryDate)}
                </LynxTypography>

                {props.data.events.length !== 0 &&
                    props.data.events.map((event) => (
                        <EventCard event={event} key={event.eventId} isHighlighted={props.data.isHighlighted} />
                    ))}
            </div>

            <DummyNodeHandle type="source" />
            <DummyNodeHandle type="target" />
        </>
    );
};
