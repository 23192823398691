import { StabilityFormAllDataApiModel, StabilityFormAuthRequest } from "api/models/stabilityForms/stabilityFormsApi";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { observer } from "mobx-react";
import { StabilityFormStatus } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { useCallback, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { StabilityFormActionAuthModal } from "../StabilityFormActionAuthModal";
import { getPermissionKey } from "helpers/permissionHelpers";
import { actions } from "models/userManagement/actions";
import { promptTooltipMessages } from "lynxConstants";
import { LynxIcon } from "icons/LynxIcon";
import { routes } from "routes";
import { useNavigate } from "react-router";
import { LynxButtonProps } from "components/LynxComponents/LynxButton/LynxButtonProps";

type ActionType = "sendForApproval" | "sendBackForEdit" | "approve";

export const FormActionButtons = observer(() => {
    const { identityStore, permissionsStore, stabilityFormStore } = useStore();
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState<ActionType | null>(null);

    const navigate = useNavigate();
    const stabilityForm = stabilityFormStore.stabilityFormDetails!;

    const permissionKeys = {
        modify: getPermissionKey(`${actions.customer.tor.stabilityForms.modify}::stabilityForm`, stabilityForm.id),
        sendForApproval: getPermissionKey(actions.customer.tor.stabilityForms.sendForApproval, stabilityForm.id),
        sendBack: getPermissionKey(actions.customer.tor.stabilityForms.sendBack, stabilityForm.id),
        approve: getPermissionKey(actions.customer.tor.stabilityForms.approve, stabilityForm.id),
        canCreateStabilityFormVersion: getPermissionKey(
            `${actions.customer.tor.stabilityForms.modify}::customer`,
            identityStore.currentCustomer.id
        ),
        canCreateVersionForForm: getPermissionKey(
            `${actions.customer.tor.stabilityForms.modify}::version`,
            stabilityForm.id
        ),
    };

    const canModify = permissionsStore.hasPermission(permissionKeys.modify);
    const canSendForApproval = permissionsStore.hasPermission(permissionKeys.sendForApproval);
    const canSendBack = permissionsStore.hasPermission(permissionKeys.sendBack);
    const canApprove = permissionsStore.hasPermission(permissionKeys.approve);
    const canCreateNewVersion = permissionsStore.hasPermission(permissionKeys.canCreateStabilityFormVersion);
    const canCreateVersionForForm = permissionsStore.hasPermission(permissionKeys.canCreateVersionForForm);

    const getTooltipMessage = useCallback(
        (form: StabilityFormAllDataApiModel): string => {
            if (form.status === StabilityFormStatus.Draft && form.ownerEmail !== identityStore.currentUser.id) {
                return promptTooltipMessages.stabilityForm.assignToYourself;
            }
            if (
                form.status === StabilityFormStatus.PendingApproval &&
                form.approverEmail !== identityStore.currentUser.id
            ) {
                return promptTooltipMessages.stabilityForm.assignToYourself;
            }
            if (form.status === StabilityFormStatus.Effective && !canCreateNewVersion) {
                return promptTooltipMessages.accessDenied;
            }

            if (form.status === StabilityFormStatus.Draft && (!canModify || !canSendForApproval)) {
                return promptTooltipMessages.accessDenied;
            }

            if (form.status === StabilityFormStatus.PendingApproval && (!canSendBack || !canApprove)) {
                return promptTooltipMessages.accessDenied;
            }

            if (form.status === StabilityFormStatus.Effective && !canCreateVersionForForm) {
                return promptTooltipMessages.stabilityForm.newVersionAlreadyCreated;
            }

            if (form.status === StabilityFormStatus.Deactivated) {
                return promptTooltipMessages.stabilityForm.formIsDeactivated;
            }

            return promptTooltipMessages.empty;
        },
        [
            canModify,
            canSendForApproval,
            canSendBack,
            canApprove,
            canCreateNewVersion,
            canCreateVersionForForm,
            identityStore.currentUser.id,
        ]
    );

    const actionHandlers: Record<ActionType, (request: StabilityFormAuthRequest) => Promise<void>> = {
        sendForApproval: stabilityFormStore.sendStabilityFormForApproval,
        sendBackForEdit: stabilityFormStore.sendStabilityFormBackForEdit,
        approve: stabilityFormStore.approveStabilityForm,
    };

    const loadingFlags: Record<ActionType, boolean> = {
        sendForApproval: stabilityFormStore.progressFlags.sendingForApproval,
        sendBackForEdit: stabilityFormStore.progressFlags.sendingBackForEdit,
        approve: stabilityFormStore.progressFlags.approvingStabilityForm,
    };

    const handleActionClick = (action: ActionType) => {
        setCurrentAction(action);
        setIsActionModalOpen(true);
    };

    const getButtonsForStatus = (status: StabilityFormStatus): LynxButtonProps[] => {
        const isApprover = stabilityForm.approverEmail === identityStore.currentUser.id;
        const isOwner = stabilityForm.ownerEmail === identityStore.currentUser.id;

        switch (status) {
            case StabilityFormStatus.Draft:
                return [
                    {
                        children: "Edit this Form",
                        variant: "secondary",
                        leftIcon: <LynxIcon name="edit" />,
                        onClick: () => navigate(routes.stabilityFormsEdit.to(stabilityForm.id)),
                        disabled: !canModify || !isOwner,
                        title: getTooltipMessage(stabilityForm),
                        loading: permissionsStore.permissionLoading(permissionKeys.modify),
                    },
                    {
                        children: "Send for Approval",
                        variant: "primary",
                        onClick: () => handleActionClick("sendForApproval"),
                        disabled: !canSendForApproval || !isOwner,
                        title: getTooltipMessage(stabilityForm),
                        loading: stabilityFormStore.progressFlags.sendingForApproval,
                    },
                ];
            case StabilityFormStatus.PendingApproval:
                return [
                    {
                        children: "Send Back for Edit",
                        variant: "secondary",
                        onClick: () => handleActionClick("sendBackForEdit"),
                        disabled: !canSendBack || !isApprover,
                        title: getTooltipMessage(stabilityForm),
                        loading: stabilityFormStore.progressFlags.sendingBackForEdit,
                    },
                    {
                        children: "Approve",
                        variant: "primary",
                        onClick: () => handleActionClick("approve"),
                        disabled: !canApprove || !isApprover,
                        title: getTooltipMessage(stabilityForm),
                        loading: stabilityFormStore.progressFlags.approvingStabilityForm,
                    },
                ];
            case StabilityFormStatus.Effective:
                return [
                    {
                        children: "Create New Version",
                        variant: "secondary",
                        leftIcon: <LynxIcon name="version" />,
                        onClick: () => navigate(routes.stabilityFormNewVersion.to(stabilityForm.id)),
                        disabled: !canCreateNewVersion || !canCreateVersionForForm,
                        title: getTooltipMessage(stabilityForm),
                        loading: permissionsStore.permissionLoading(permissionKeys.canCreateVersionForForm),
                    },
                ];
            case StabilityFormStatus.Deactivated:
                return [
                    {
                        children: "Create New Version",
                        variant: "secondary",
                        leftIcon: <LynxIcon name="version" />,
                        onClick: () => {},
                        disabled: true,
                        title: getTooltipMessage(stabilityForm),
                    },
                ];
            default:
                return [];
        }
    };

    const buttons = getButtonsForStatus(stabilityForm.status);

    return (
        <>
            {buttons.map((button, index) => (
                <PromptTooltip key={index} placement="top" title={button.title!}>
                    <LynxButton
                        size="medium"
                        variant={button.variant}
                        disabled={button.disabled}
                        loading={button.loading}
                        onClick={button.onClick}
                        leftIcon={button.leftIcon}
                    >
                        {button.children}
                    </LynxButton>
                </PromptTooltip>
            ))}

            {currentAction && (
                <StabilityFormActionAuthModal
                    isModalOpen={isActionModalOpen}
                    setIsModalOpen={setIsActionModalOpen}
                    actionRequest={actionHandlers[currentAction]}
                    loading={loadingFlags[currentAction]}
                    isApproveNewVersion={currentAction === "approve" && stabilityForm.version > 1}
                />
            )}
        </>
    );
});
