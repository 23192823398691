import { ReactFlowProvider } from "@xyflow/react";
import { GetBatchDistributionDiagramRequest } from "api/models/events/eventsApi";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { LynxInfoBadge } from "components/LynxComponents/LynxInfoBadge";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { FullScreenLoadingIndicatorWrapper } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicatorWrapper";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone, getFormatedTimezoneOffset } from "helpers/timezoneHelper";
import { isGuidValid } from "helpers/typeValidationHelpers";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { BatchDistributionDiagram } from "./BatchDistributionDiagram";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";
import { BatchDistributionDiagramPopup } from "./BatchDistributionDiagramPopup";
import { Grid } from "@material-ui/core";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { assessmentTexts } from "lynxConstants/assessmentTexts";

export const BatchDistributionDiagramPage = observer(() => {
    const classes = batchDistributionDiagramPageStyles();
    const { eventId = "", assessmentId = "" } = useParams();

    const navigate = useNavigate();
    const { identityStore, permissionsStore, commonStore, batchDistributionDiagramStore } = useStore();

    const customerId = identityStore.currentCustomer.id;
    const diagram = batchDistributionDiagramStore.diagramData;

    const viewEventDetailsPermissionKey = getPermissionKey(actions.customer.tor.events.viewDetails, customerId);
    const viewEventDetailsPermissionStatus = permissionsStore.getPermissionStatus(viewEventDetailsPermissionKey);

    const viewStabilityFormDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.viewDetails,
        customerId
    );

    const viewStabilityFormDetailsPermissionStatus = permissionsStore.getPermissionStatus(
        viewStabilityFormDetailsPermissionKey
    );

    const customerConfig = identityStore.customerConfiguration;

    const requestPreconditionsMet =
        viewEventDetailsPermissionStatus === MarvelPermissionStatus.Allow &&
        isGuidValid(eventId) &&
        isGuidValid(assessmentId) &&
        customerConfig.batchTrackingEnabled;

    useEffect(() => {
        commonStore.setShowSidebarAndHeader(false);

        return () => {
            commonStore.setShowSidebarAndHeader(true);
            commonStore.setShowGeneralErrorPageToFalse();
            batchDistributionDiagramStore.resetBatchDistributionDiagram();
        };
    }, []);

    useEffect(() => {
        if (!isGuidValid(eventId) || !isGuidValid(assessmentId)) {
            navigate(routes.pageNotFound);
        }
    }, [eventId, assessmentId]);

    useEffect(() => {
        if (viewEventDetailsPermissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [viewEventDetailsPermissionStatus]);

    useEffect(() => {
        const func = async () => {
            if (requestPreconditionsMet) {
                const request: GetBatchDistributionDiagramRequest = {
                    customerId,
                    eventId,
                    assessmentId,
                };
                const result = await batchDistributionDiagramStore.getBatchDistributionDiagram(request);

                if ((result !== null && result.stabilityFormId === null) || !customerConfig.batchTrackingEnabled) {
                    navigate(routes.eventDetails.to(eventId));
                }
            }
        };

        func();
    }, [requestPreconditionsMet]);

    const title = `Batch ${diagram.batchNumber || commonConstants.emptyValue} history`;

    const showLoading =
        diagram.eventId === "" ||
        batchDistributionDiagramStore.progressFlags.loadingBatchDistributionDiagram ||
        diagram.stabilityFormId === null;

    const closedAt = convertDateToSelectedTimezone(diagram.assessmentClosedAt, diagram.eventTimezone).format(
        commonConstants.fullDateTimeFormat
    );
    const formattedTz = getFormatedTimezoneOffset(diagram.assessmentClosedAt, diagram.eventTimezone);
    const closedAssessmentText = `${assessmentTexts.evaluationComplete} ${closedAt} ${formattedTz}`;

    return (
        <GeneralErrorComponentWrapper>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <FullScreenLoadingIndicatorWrapper showLoading={showLoading}>
                <main className={classes.root}>
                    <div className={classes.leftSide}>
                        <div className={classes.headerContainer}>
                            <div className={classes.header}>
                                <div className={classes.headerLeftSide}>
                                    <div className={classes.headerLeftSideLinks}>
                                        <LynxRedirectLink
                                            target="_blank"
                                            to={routes.eventDetails.to(eventId)}
                                            variant="body-s"
                                        >
                                            Event {diagram.eventDisplayId}
                                        </LynxRedirectLink>
                                        <div className={classes.circle}></div>
                                        <LynxRedirectLink
                                            target="_blank"
                                            variant="body-s"
                                            disabled={
                                                viewStabilityFormDetailsPermissionStatus === MarvelPermissionStatus.Deny
                                            }
                                            to={routes.stabilityFormDetails.to(diagram.stabilityFormId ?? "")}
                                        >
                                            {diagram.productFullName}
                                        </LynxRedirectLink>
                                    </div>
                                    <Grid container alignItems="center" spacing={4}>
                                        <Grid item>
                                            <LynxTypography variant="h2">{title}</LynxTypography>
                                        </Grid>
                                        <Grid item>
                                            <LynxCheckBox
                                                label="Highlight Relevant Events"
                                                checked={batchDistributionDiagramStore.areRelevantObjectsHighlighted}
                                                onChange={() => {
                                                    batchDistributionDiagramStore.setAreRelevantObjectsHighlighted(
                                                        !batchDistributionDiagramStore.areRelevantObjectsHighlighted
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <LynxTypography variant="body-s" color="neutral400">
                                    History shown
                                    <br />
                                    from&nbsp;
                                    <Span color="neutral600" variant="body-s">
                                        {dateToFormat(diagram.historyShownFrom, commonConstants.shortDateFormat, true)}
                                    </Span>
                                    &nbsp;to&nbsp;
                                    <Span color="neutral600" variant="body-s">
                                        {dateToFormat(diagram.historyShownTo, commonConstants.shortDateFormat, true)}
                                    </Span>
                                </LynxTypography>
                            </div>
                            {diagram.assessmentClosedAt && <LynxInfoBadge text={closedAssessmentText} />}
                        </div>
                        <ReactFlowProvider>
                            <BatchDistributionDiagram />
                        </ReactFlowProvider>
                    </div>
                    {batchDistributionDiagramStore.groupedDeliveriesDetails && <BatchDistributionDiagramPopup />}
                </main>
            </FullScreenLoadingIndicatorWrapper>
        </GeneralErrorComponentWrapper>
    );
});
