import { LynxAssessmentCard } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCard";
import { BatchHoursReduced } from "components/LynxComponents/LynxAssessmentCard/LynxAssessmentCardProps";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { areBatchHoursReduced } from "helpers/areBatchHoursReduced";
import { formatDurationToString } from "helpers/formatDurationToString";
import { getPermissionKey } from "helpers/permissionHelpers";
import { formatTemperatureRange } from "helpers/rangeHelpers";
import { breakLines } from "helpers/stringHelpers";
import { commonConstants, productDecisionsText, promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { AssessmentDecision, AssessmentStatus } from "models/thorEvents/eventModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { AssessmentAuthModal } from "../AssessmentAuthModal";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { ProductDecisionProps } from "./ProductDecisionProps";

export const ProductDecision = observer(({ assessment }: ProductDecisionProps) => {
    const classes = assessmentDetailsStyles();
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userDecision, setUserDecision] = useState<AssessmentDecision | undefined>();

    const permission = getPermissionKey(actions.customer.tor.events.approve, thorEventViewStore.eventDetails.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permission);

    const isDecisionMakingInProgress =
        thorEventViewStore.eventDetails.qaUserId === identityStore.currentUser.id &&
        assessment.status === AssessmentStatus.PendingDecision;

    const makeDecision = (userDecision: AssessmentDecision) => {
        setUserDecision(userDecision);
        setIsModalOpen(true);
    };

    const getInfoBadgeText = () => {
        if (assessment.decision === null || assessment.impacts.length === 0) {
            return "";
        }

        const hoursReduced = areBatchHoursReduced(assessment.decision, assessment.isWithFirstEconomicCustomer);
        const hoursReducedText = hoursReduced === BatchHoursReduced.Yes ? "reduced" : "not reduced";
        const firstLine = `Remaining stability time for Batch #${assessment.batchNumber} is ${hoursReducedText}:`;

        const rangeLines = assessment.impacts
            .filter((x) => x.excursionDuration > 0)
            .map((x) => {
                const range = formatTemperatureRange(x.lowerLimit, x.upperLimit, x.lowerInclusive, x.upperInclusive);
                const rsb = formatDurationToString(hoursReduced === BatchHoursReduced.Yes ? x.productRsb : x.batchRsb);
                return `- in temperature range of ${range} remaining stability time is ${rsb}`;
            });

        return breakLines([firstLine, ...rangeLines].join("\n"));
    };

    const getTooltipMessage = () => {
        return permissionStatus !== MarvelPermissionStatus.Allow
            ? promptTooltipMessages.accessDenied
            : promptTooltipMessages.empty;
    };

    if (!isDecisionMakingInProgress && assessment.decision === null) {
        return null;
    }

    return (
        <>
            {isDecisionMakingInProgress && (
                <AssessmentDetailsSection title="Product Decision">
                    <div className={classes.assessmentDecisionButtonsContainer}>
                        <PromptTooltip placement="top" title={getTooltipMessage()}>
                            <LynxButton
                                size="medium"
                                onClick={() => makeDecision(AssessmentDecision.NoImpactOnProductQuality)}
                                disabled={permissionStatus !== MarvelPermissionStatus.Allow}
                                loading={permissionStatus === MarvelPermissionStatus.Loading}
                            >
                                {productDecisionsText.productCanBeUsed}
                            </LynxButton>
                        </PromptTooltip>
                        <PromptTooltip placement="top" title={getTooltipMessage()}>
                            <LynxButton
                                size="medium"
                                variant="secondary"
                                onClick={() => makeDecision(AssessmentDecision.ExistingImpactOnProductQuality)}
                                disabled={permissionStatus !== MarvelPermissionStatus.Allow}
                                loading={permissionStatus === MarvelPermissionStatus.Loading}
                            >
                                {productDecisionsText.productCannotBeUsed}
                            </LynxButton>
                        </PromptTooltip>
                    </div>
                    {userDecision !== undefined && (
                        <AssessmentAuthModal
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            assessmentDecision={userDecision}
                            assessment={assessment}
                        />
                    )}
                </AssessmentDetailsSection>
            )}

            {assessment.decision !== null && (
                <AssessmentDetailsSection title="Product Decision">
                    <LynxAssessmentCard
                        email={assessment.qaUserId}
                        firstName={assessment.qaFirstName}
                        lastName={assessment.qaLastName}
                        action={"has made a decision"}
                        actionDate={assessment.completedDate}
                        infoBadgeText={getInfoBadgeText()}
                    >
                        <div>
                            <LynxTypography variant="body-s" className={classes.productDecisionHeader}>
                                Final Product Decision
                            </LynxTypography>
                            <LynxTypography>
                                {assessment.decision === AssessmentDecision.NoImpactOnProductQuality ? (
                                    <LynxTypography className={classes.safeToUse}>
                                        {productDecisionsText.productCanBeUsed}
                                    </LynxTypography>
                                ) : (
                                    <LynxTypography className={classes.notSafeToUse}>
                                        {productDecisionsText.productCannotBeUsed}
                                    </LynxTypography>
                                )}
                            </LynxTypography>
                            <LynxTypography variant="body-s" className={classes.justificationHeader}>
                                Justification
                            </LynxTypography>
                            <LynxTypography>
                                {!assessment.justification ? commonConstants.emptyValue : assessment.justification}
                            </LynxTypography>
                        </div>
                    </LynxAssessmentCard>
                </AssessmentDetailsSection>
            )}
        </>
    );
});
