import { StabilityFormApiInputModel } from "api/models/stabilityForms/stabilityFormsApi";
import { formikRangesEqual } from "helpers/rangeHelpers";
import { commonConstants } from "lynxConstants";
import {
    formikModels,
    lynxArray,
    lynxBoolean,
    lynxNumber,
    lynxString,
    temperatureRangeValidationSchemaObj,
    validationConstants,
    validationErrorMessages,
    YupSchema,
} from "validation";
import { array, object, ValidationError } from "yup";

export const stabilityFormValidationSchema = object<YupSchema<StabilityFormApiInputModel>>({
    ...temperatureRangeValidationSchemaObj,
    number: lynxString().max(100).required(),
    additionalInfo: lynxString().nullable().max(100),
    productBusinessKey: lynxString().onlyZerosNotAllowed().required().max(100),
    productName: lynxString().required().max(200),
    genericProductName: lynxString().nullable().max(200),
    // TODO: revisit this
    SKUs: lynxArray().of(
        lynxString()
            .required()
            .max(18)
            .matches(/^([0-9]+)$/, "Invalid value")
    ),
    storageTypeId: lynxString().required(),
    presentationId: lynxString().nullable(),
    categoryId: lynxString().nullable(),
    expiryPeriodInHours: lynxNumber().nullable().min(0).maxDurationLimit(),
    productSpec: lynxString().nullable().max(500),
    freezeCycleLimit: lynxNumber().nullable().min(0).max(100),
    representations: lynxArray()
        .min(1, validationErrorMessages.stabilityForm.atLeastOneRepresentation)
        .of(
            object({
                id: lynxString().nullable(),
                doseFormId: lynxString().required(),
                allDosagesFlag: lynxBoolean().required(),
                dosage: lynxNumber()
                    .moreThan(0)
                    .max(validationConstants.maxAllowedDosage)
                    .when("allDosagesFlag", {
                        is: true,
                        then: (x) => x.nullable().oneOf([null]),
                        otherwise: (x) => x.required(),
                    }),
                unitOfMeasureId: lynxString().when("allDosagesFlag", {
                    is: true,
                    then: (x) => x.nullable().oneOf([null]),
                    otherwise: (x) => x.required(),
                }),
            })
        )
        .test({
            name: "noRepresentationDuplicates",
            message: validationErrorMessages.stabilityForm.duplicateDosages,
            test: (value, ctx) => {
                const duplicates = value
                    ?.map((x, i) => ({ ...x, index: i }))
                    .filter(
                        (x) =>
                            value.filter(
                                (y) =>
                                    y.doseFormId === x.doseFormId &&
                                    (y.allDosagesFlag ||
                                        x.allDosagesFlag ||
                                        (y.dosage === x.dosage && y.unitOfMeasureId === x.unitOfMeasureId))
                            ).length > 1
                    );

                return duplicates && duplicates.length > 0
                    ? new ValidationError(
                          duplicates.map((x) =>
                              ctx.createError({
                                  path: `${ctx.path}[${x.index}].doseFormId`,
                              })
                          )
                      )
                    : true;
            },
        }),
    temperatureRanges: array()
        .min(1, validationErrorMessages.stabilityForm.atLeastOneTemperatureRange)
        .test({
            name: "NoDuplicateRanges",
            message: validationErrorMessages.stabilityForm.duplicateRanges,
            test: (value: formikModels.TemperatureRangeFormikModel[] | undefined, ctx) => {
                const duplicates = value
                    ?.map((x, i) => ({ ...x, index: i }))
                    .filter((x) => value.filter((y) => formikRangesEqual(x, y)).length > 1);

                return duplicates && duplicates.length > 0
                    ? new ValidationError(
                          duplicates.map((x) => ctx.createError({ path: `${ctx.path}.${x.index}.lowerLimit` }))
                      )
                    : true;
            },
        })
        .of(
            object({
                ...temperatureRangeValidationSchemaObj,
                id: lynxString().nullable(),
                references: lynxString().nullable().max(500),
                totalStabilityBudget: lynxNumber().nullable().min(0).maxDurationLimit(),
                remainingStabilityBudget: lynxNumber()
                    .min(0)
                    .maxDurationLimit()
                    .when("rangeRegions", {
                        is: (rangeRegions: formikModels.TemperatureRangeRegionFormikModel[]) =>
                            !!rangeRegions && rangeRegions.length <= 0,
                        then: (x) => x.required(),
                        otherwise: (x) => x.nullable().oneOf([null]),
                    }),
                medicalInfo: lynxNumber().nullable().min(0).maxDurationLimit(),
                rangeRegions: array()
                    .of(
                        object({
                            id: lynxString().nullable(),
                            regionId: lynxString().required(),
                            medicalInfo: lynxNumber().nullable().min(0).maxDurationLimit(),
                            flows: array().of(
                                object({
                                    id: lynxString().nullable(),
                                    name: lynxString().nullable().max(100),
                                    references: lynxString().nullable().max(500),
                                    steps: lynxString().nullable().max(250),
                                    plannedDeduction: lynxNumber().min(0).nullable().maxDurationLimit(),
                                    remainingStabilityBudget: lynxNumber().min(0).required().maxDurationLimit(),
                                })
                            ),
                        })
                    )
                    .test({
                        name: "regionsHaveNoDuplicateId",
                        message: validationErrorMessages.stabilityForm.duplicateRegionsPerRange,
                        test: (value, ctx) => {
                            const duplicates = value
                                ?.map((x, i) => ({ ...x, index: i }))
                                .filter((x) => value.filter((y) => x.regionId === y.regionId).length > 1);

                            return duplicates && duplicates.length > 0
                                ? new ValidationError(
                                      duplicates.map((x) =>
                                          ctx.createError({ path: `${ctx.path}.${x.index}.regionId` })
                                      )
                                  )
                                : true;
                        },
                    })
                    .test({
                        name: "eitherAllRegionsOrOnlySpecificRegions",
                        message: validationErrorMessages.stabilityForm.eitherAllCountriesOrSpecific,
                        test: (value, ctx) => {
                            if (value && value.length > 0) {
                                const allRegionsIndex = value.findIndex(
                                    (x) => x.regionId === commonConstants.allCountriesAndRegions
                                );
                                if (allRegionsIndex !== -1 && value.length !== 1) {
                                    return ctx.createError({
                                        path: `${ctx.path}[${allRegionsIndex}].regionId`,
                                        message: validationErrorMessages.stabilityForm.eitherAllCountriesOrSpecific,
                                    });
                                }
                            }
                            return true;
                        },
                    }),
            })
        ),
});
