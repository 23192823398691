import clsx from "clsx";
import { EventStatus } from "models/thorEvents/eventModels";
import React from "react";
import { statusBoxStyles } from "./statusBoxStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { eventIsClosed } from "helpers/eventIsClosed";

export interface EventRowStatusProps {
    status: EventStatus;
}
export default function EventRowStatus({ status }: EventRowStatusProps) {
    const classes = statusBoxStyles();
    const getText = (status: EventStatus) => {
        switch (status) {
            case EventStatus.New:
                return "New";
            case EventStatus.PendingReview:
                return "Pending Review";
            case EventStatus.PendingApproval:
                return "Pending Approval";
            case EventStatus.Closed:
                return "Completed";
            case EventStatus.AutoReleased:
                return "Auto-Released";
        }
    };
    return (
        <div>
            <LynxTypography
                className={clsx(classes.statusPadding, classes.statusBox, {
                    [classes.event_new]: status === EventStatus.New,
                    [classes.event_pendingReview]: status === EventStatus.PendingReview,
                    [classes.event_pendingApproval]: status === EventStatus.PendingApproval,
                    [classes.event_completed]: eventIsClosed(status),
                })}
                variant="body-sbold-s-wide"
            >
                {getText(status)}
            </LynxTypography>
        </div>
    );
}
