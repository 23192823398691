import { Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { FieldArray } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { TrueFalseType } from "models/thorEvents/eventModels";
import { ChangeEvent } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModels, useLynxFormikContext } from "validation";
import { stabilityFormStyles } from "../StabilityFormStyles";

const RepresentationFieldsArea = observer(() => {
    const formik = useLynxFormikContext<formikModels.StabilityFormFormikModel>();
    const classes = stabilityFormStyles();
    const { customerDataStore } = useStore();

    const representationTableHeaders = [
        { key: 0, label: "Dose Form", required: true, width: "30%" },
        { key: 1, label: "Dosage", required: true, width: "58%" },
        { key: 2, label: "", required: false, width: "2%" },
    ];

    const handleAllDosagesFlagChange = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        index: number
    ) => {
        const value = e.target.value as TrueFalseType;

        if (value === TrueFalseType.True) {
            formik.setValueOrEmptyStr(`representations.${index}.dosage`, "", true);
            formik.setValueOrEmptyStr(`representations.${index}.unitOfMeasureId`, "", true);
        }

        formik.setFieldValue(`representations.${index}.allDosagesFlag`, value);
    };

    return (
        <FieldArray
            name={`representations`}
            render={(representationHelpers) => (
                <Grid container className={classes.marginTopMedium} direction="column">
                    <Grid item>
                        <LynxTypography variant="h3" color="neutral400">
                            Dose forms and dosages
                        </LynxTypography>
                    </Grid>
                    <Grid item xs={8}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {representationTableHeaders.map((x) => (
                                        <TableCell
                                            key={x.key}
                                            className={clsx(classes.paddingBottomSmall, classes.tableCellNoBorder)}
                                            style={{ width: x.width }}
                                        >
                                            <LynxTypography variant="body-s" color="neutral400">
                                                {x.required ? <LabelWithRequiredSymbol label={x.label} /> : x.label}
                                            </LynxTypography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formik.values.representations.map((representation, representationIndex) => (
                                    <TableRow
                                        className={clsx(classes.tableContentRow, classes.verticalAlignmentBaseLine)}
                                        key={representation.metadata.key}
                                    >
                                        <TableCell
                                            className={clsx(classes.paddingBottomZero, classes.paddingRightZero)}
                                        >
                                            <LynxSelectForm
                                                name={`representations.${representationIndex}.doseFormId`}
                                                placeholder="Select Dose Form"
                                            >
                                                {customerDataStore.productSupportInfo.doseForms.map((x) => (
                                                    <MenuItem value={x.id} key={x.id}>
                                                        {x.name}
                                                    </MenuItem>
                                                ))}
                                            </LynxSelectForm>
                                        </TableCell>
                                        <TableCell
                                            className={clsx(classes.paddingBottomZero, classes.paddingRightZero)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <LynxSelectForm
                                                        name={`representations.${representationIndex}.allDosagesFlag`}
                                                        defaultValue={0}
                                                        onChange={(
                                                            e: ChangeEvent<{
                                                                name?: string | undefined;
                                                                value: unknown;
                                                            }>
                                                        ) => handleAllDosagesFlagChange(e, representationIndex)}
                                                    >
                                                        <MenuItem value={TrueFalseType.False} key={0}>
                                                            Specify dosage
                                                        </MenuItem>
                                                        <MenuItem value={TrueFalseType.True} key={1}>
                                                            All
                                                        </MenuItem>
                                                    </LynxSelectForm>
                                                </Grid>

                                                <Grid item xs>
                                                    <LynxInputForm
                                                        multiline
                                                        name={`representations.${representationIndex}.dosage`}
                                                        placeholder="Amount"
                                                        disabled={
                                                            formik.values.representations[representationIndex]
                                                                .allDosagesFlag === TrueFalseType.True
                                                        }
                                                    />
                                                </Grid>

                                                <Grid item xs>
                                                    <LynxSelectForm
                                                        name={`representations.${representationIndex}.unitOfMeasureId`}
                                                        placeholder="Unit"
                                                        disabled={
                                                            formik.values.representations[representationIndex]
                                                                .allDosagesFlag === TrueFalseType.True
                                                        }
                                                    >
                                                        {customerDataStore.productSupportInfo.unitOfMeasures.map(
                                                            (x) => (
                                                                <MenuItem value={x.id} key={x.id}>
                                                                    {x.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </LynxSelectForm>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className={classes.verticalAlignTop}>
                                            <LynxButton
                                                variant="icon"
                                                size="medium"
                                                className={classes.trashIconMargin}
                                                disabled={formik.values.representations.length <= 1}
                                                onClick={() => {
                                                    if (formik.values.representations.length <= 1) {
                                                        return;
                                                    }

                                                    representationHelpers.remove(representationIndex);
                                                }}
                                            >
                                                <LynxIcon name="trash" />
                                            </LynxButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item className={classes.marginTopMedium}>
                        <LynxButton
                            variant="tertiary"
                            onClick={() => {
                                const value: formikModels.ProductRepresentationFormikModel = {
                                    dosage: "",
                                    unitOfMeasureId: "",
                                    doseFormId: "",
                                    allDosagesFlag: TrueFalseType.False,
                                    metadata: {
                                        id: "",
                                        key: crypto.randomUUID(),
                                    },
                                };

                                representationHelpers.push(value);
                            }}
                            leftIcon={<LynxIcon name="plusSmall" />}
                        >
                            Add Dose Form & Dosage
                        </LynxButton>
                    </Grid>
                </Grid>
            )}
        />
    );
});

export default RepresentationFieldsArea;
