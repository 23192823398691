import { Grid } from "@material-ui/core";
import { CustomersDataWithSearch } from "api/models/userManagement/userManagementApi";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import { LynxSelectWithSearchProps } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearchProps";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { LocationInfo } from "models/thorEvents/eventModels";
import { useStore } from "store/StoreConfigs";
import { SectionCard } from "../../ReusableComponents/Cards/SectionCard";
import { eventCreationStyles } from "./EventCreationStyles";
import { formikModels, useLynxFormikContext } from "validation";

const TransportationLocationSection = observer(() => {
    const { customerDataStore, identityStore } = useStore();
    const formik = useLynxFormikContext<formikModels.EventFormikModel>();
    const classes = eventCreationStyles();

    const getLocations = (value: string | undefined) => {
        const request: CustomersDataWithSearch = {
            searchValue: value,
            customerId: identityStore.currentCustomer.id,
            pageNumber: 1,
            pageSize: 5,
        };

        customerDataStore.loadCustomerLocations(request);
    };

    const handleOriginChange: LynxSelectWithSearchProps<LocationInfo, false, false, false>["onChange"] = (
        _event,
        value,
        _reason,
        _details
    ) => {
        formik.setValueOrEmptyStr("deliveryInformation.originId", value?.id, true);
        formik.setValueOrEmptyStr("deliveryInformation.metadata.originCode", value?.code);
        formik.setValueOrEmptyStr("deliveryInformation.metadata.originName", value?.name);
    };

    const handleDestinationChange: LynxSelectWithSearchProps<LocationInfo, false, false, false>["onChange"] = (
        _event,
        value,
        _reason,
        _details
    ) => {
        formik.setValueOrEmptyStr("deliveryInformation.destinationId", value?.id, true);
        formik.setValueOrEmptyStr("deliveryInformation.metadata.destinationCode", value?.code);
        formik.setValueOrEmptyStr("deliveryInformation.metadata.destinationName", value?.name);
    };

    const originValue = formik.values.deliveryInformation.originId
        ? {
              id: formik.values.deliveryInformation.originId,
              code: formik.values.deliveryInformation.metadata.originCode,
              name: formik.values.deliveryInformation.metadata.originName,
          }
        : null;

    const destinationValue = formik.values.deliveryInformation.destinationId
        ? {
              id: formik.values.deliveryInformation.destinationId,
              code: formik.values.deliveryInformation.metadata.destinationCode,
              name: formik.values.deliveryInformation.metadata.destinationName,
          }
        : null;

    return (
        <SectionCard>
            <LynxTypography variant="h2" className={classes.titleMargin}>
                Transportation Information
            </LynxTypography>
            <Grid container justifyContent="space-between" spacing={8}>
                <Grid container item xs={6} className={classes.locationBorder}>
                    <Grid item xs={12} className={classes.autocompleteMargin}>
                        <LynxTypography variant="h3" color="neutral400" className={classes.subtitle}>
                            <LabelWithRequiredSymbol label="Origin" />
                        </LynxTypography>

                        <LynxSelectWithSearch
                            value={originValue}
                            disablePortal
                            forcePopupIcon
                            isSearchWithAutocomplete
                            triggerSearchOnClear
                            search={getLocations}
                            placeholder="Search by site ID or Name of Location"
                            options={customerDataStore.locations}
                            loading={customerDataStore.progressFlags.loadLocations}
                            freeSolo={false}
                            multiple={false}
                            disableClearable={false}
                            onChange={handleOriginChange}
                            inputProps={{
                                name: "deliveryInformation.originId",
                                error: !!formik.errors?.deliveryInformation?.originId,
                                assistiveText: formik.errors?.deliveryInformation?.originId,
                            }}
                            disablePopupIconInteractions
                            getOptionLabel={(x) => [x.code, x.name].filter((x) => x).join(" ")}
                        />
                    </Grid>
                    <Grid container item spacing={4} className={classes.rowPadding}>
                        <Grid item xs={4}>
                            <LynxInputForm
                                readOnly
                                name="deliveryInformation.metadata.originCode"
                                label="Site ID"
                                placeholder={commonConstants.emptyValue}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <LynxInputForm
                                readOnly
                                name="deliveryInformation.metadata.originName"
                                label="Name of Location"
                                placeholder={commonConstants.emptyValue}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={6}>
                    <Grid item xs={12} className={classes.autocompleteMargin}>
                        <LynxTypography variant="h3" color="neutral400" className={classes.subtitle}>
                            <LabelWithRequiredSymbol label="Destination" />
                        </LynxTypography>

                        <LynxSelectWithSearch
                            value={destinationValue}
                            disablePortal
                            forcePopupIcon
                            isSearchWithAutocomplete
                            triggerSearchOnClear
                            search={getLocations}
                            placeholder="Search by site ID or Name of Location"
                            options={customerDataStore.locations}
                            loading={customerDataStore.progressFlags.loadLocations}
                            freeSolo={false}
                            multiple={false}
                            disableClearable={false}
                            onChange={handleDestinationChange}
                            inputProps={{
                                name: "deliveryInformation.destinationId",
                                error: !!formik.errors?.deliveryInformation?.destinationId,
                                assistiveText: formik.errors?.deliveryInformation?.destinationId,
                            }}
                            disablePopupIconInteractions
                            getOptionLabel={(x) => [x.code, x.name].filter((x) => x).join(" ")}
                        />
                    </Grid>
                    <Grid container item spacing={4} className={classes.rowPadding}>
                        <Grid item xs={4}>
                            <LynxInputForm
                                readOnly
                                name="deliveryInformation.metadata.destinationCode"
                                label="Site ID"
                                placeholder={commonConstants.emptyValue}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <LynxInputForm
                                readOnly
                                name="deliveryInformation.metadata.destinationName"
                                label="Name of Location"
                                placeholder={commonConstants.emptyValue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SectionCard>
    );
});

export default TransportationLocationSection;
