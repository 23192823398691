import { Grid } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { getPermissionKey } from "helpers/permissionHelpers";
import { convertDateToSelectedTimezone } from "helpers/timezoneHelper";
import { commonConstants, promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { LynxIcon } from "icons/LynxIcon";
import EventRowStatus from "../utilityComponents/EventRowStatus";
import { EventInfoProps } from "./EventInfoProps";
import { eventInfoStyles } from "./EventInfoStyles";
import { FixedHeaderRenderer } from "components/ReusableComponents/FixedHeaderRenderer";
import { withFixed } from "helpers/withFixed";
import { eventIsClosed } from "helpers/eventIsClosed";

export const EventInfo = observer((props: EventInfoProps) => {
    const classes = eventInfoStyles();
    const { thorEventViewStore, identityStore, permissionsStore } = useStore();
    const navigate = useNavigate();

    const details = thorEventViewStore.eventDetails;

    const key = getPermissionKey(`${actions.customer.tor.events.editManualEvent}::event`, details.id);

    const editManualEventPermissionStatus = permissionsStore.getPermissionStatus(key);

    const isDisabledEditEventButton =
        editManualEventPermissionStatus !== MarvelPermissionStatus.Allow || !props.allAssessmentsArePendingReview;

    const getTooltipMessage = () => {
        const isCurrentUserIsReviever = details.reviewerUserId === identityStore.currentUser.id;
        const isCurrentUserIsQA = details.qaUserId === identityStore.currentUser.id;

        switch (true) {
            case !props.allAssessmentsArePendingReview:
                return promptTooltipMessages.event.reviewCompleted;
            case !(isCurrentUserIsReviever || isCurrentUserIsQA):
                return promptTooltipMessages.event.assignToYourself;
            case editManualEventPermissionStatus !== MarvelPermissionStatus.Allow:
                return promptTooltipMessages.accessDenied;
            default:
                return promptTooltipMessages.empty;
        }
    };

    const renderContent = (isFixed: boolean) => {
        return (
            <Grid container className={withFixed(classes.root, isFixed)}>
                <Grid item container xs={8} direction="row">
                    <Grid item container direction="column">
                        {!isFixed && (
                            <LynxTypography variant="body-s" color="neutral400">
                                Event ID
                            </LynxTypography>
                        )}
                    </Grid>
                    <Grid item>
                        <LynxTypography variant={isFixed ? "h2" : "h1"} component="h1">
                            {details.displayId}
                        </LynxTypography>
                    </Grid>
                    <Grid item className={classes.idAndStatus}>
                        <EventRowStatus status={details.status} />
                    </Grid>
                </Grid>
                <Grid item container xs={4} direction="column" className={classes.secondSection}>
                    <Grid item className={classes.buttons}>
                        {thorEventViewStore.eventDetails.handlingUnits.length === 0 && (
                            <PromptTooltip placement="top" title={getTooltipMessage()}>
                                <LynxButton
                                    variant="secondary"
                                    size="medium"
                                    disabled={isDisabledEditEventButton}
                                    loading={editManualEventPermissionStatus === MarvelPermissionStatus.Loading}
                                    onClick={() => {
                                        navigate(routes.eventsEdit.to(details.id));
                                    }}
                                    leftIcon={<LynxIcon name="edit" />}
                                >
                                    Edit this Event
                                </LynxButton>
                            </PromptTooltip>
                        )}
                    </Grid>
                </Grid>
                {!isFixed && (
                    <Grid item container className={classes.dates}>
                        <Span variant="body-s" color="neutral400">
                            Event Created:&nbsp;
                            {convertDateToSelectedTimezone(
                                thorEventViewStore.eventDetails.createdAt,
                                thorEventViewStore.eventDetails.timezone
                            ).format(commonConstants.fullDateTimeFormat)}
                            &nbsp;
                        </Span>
                        {thorEventViewStore.eventDetails.createdBy &&
                            thorEventViewStore.eventDetails.createdBy !== commonConstants.system && (
                                <Span variant="body-s" color="neutral400">
                                    Created By: {thorEventViewStore.eventDetails.createdBy}&nbsp;
                                </Span>
                            )}
                        {eventIsClosed(details) && (
                            <Span variant="body-s" color="neutral400">
                                Event Completed:&nbsp;
                                {convertDateToSelectedTimezone(
                                    thorEventViewStore.eventDetails.closedAt,
                                    thorEventViewStore.eventDetails.timezone
                                ).format(commonConstants.fullDateTimeFormat)}
                            </Span>
                        )}
                    </Grid>
                )}
            </Grid>
        );
    };

    return (
        <>
            <FixedHeaderRenderer level="second" topMargin={-24}>
                {renderContent(true)}
            </FixedHeaderRenderer>
            {renderContent(false)}
        </>
    );
});
