import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import LynxJustificationModal from "components/LynxComponents/LynxJustificationModal/LynxJustificationModal";
import { AuthModal } from "components/ReusableComponents/AuthModal/AuthModal";
import { oktaAuth } from "oktaAuth";
import { useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { formikModels, validationHelpers } from "validation";
import { SendEventBackForReviewRequest } from "api/models/events/eventsApi";

interface SendEventBackForReviewAuthModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

export const SendEventBackForReviewAuthModal = (props: SendEventBackForReviewAuthModalProps) => {
    const navigate = useNavigate();
    const { isModalOpen, setIsModalOpen } = props;
    const { thorEventViewStore, identityStore } = useStore();

    const [numberOfRetries, setNumberOfRetries] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [isShowReAuthenticate, setIsShowReAuthenticate] = useState(false);
    const [justification, setJustification] = useState("");

    // TODO: move logic to a common handler
    const handleResponse = async (statusCode: number, errors: ApiValidationError[]) => {
        switch (statusCode) {
            case 200:
                setNumberOfRetries(2);
                navigate(routes.eventDetails.to(thorEventViewStore.eventDetails.id));
                break;
            case 401:
                setNumberOfRetries(numberOfRetries - 1);
                setErrorMessage(numberOfRetries + " login attempt remaining, please try again or contact an admin");
                if (numberOfRetries <= 0) {
                    setNumberOfRetries(2);
                    await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}${routes.login}` });
                }
                break;
            case 400:
                thorEventViewStore.setEventFormikErrors(
                    validationHelpers.generateFormikErrorsFromApi<formikModels.EventFormikModel>(errors)
                );
                setIsShowReAuthenticate(false);
                break;
            default:
                setErrorMessage("Something went wrong. Please, try again");
                break;
        }
    };

    const submit = async (authModel: AuthModel) => {
        const request: SendEventBackForReviewRequest = {
            customerId: identityStore.currentCustomer.id,
            eventId: thorEventViewStore.eventDetails.id,
            justification: justification,
            authModel: authModel,
            callback: handleResponse,
        };

        await thorEventViewStore.sendBackForReview(request);
    };

    return (
        <>
            <LynxJustificationModal
                open={isModalOpen}
                title={"Confirm your actions by providing comments."}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                onConfirm={() => {
                    setIsModalOpen(false);
                    setIsShowReAuthenticate(true);
                }}
                value={justification}
                handleValueChange={setJustification}
            />
            <AuthModal
                isOpen={isShowReAuthenticate}
                onCancel={() => {
                    setIsShowReAuthenticate(false);
                }}
                onClose={() => {
                    setIsShowReAuthenticate(false);
                }}
                onSubmit={(authModel: AuthModel) => {
                    submit(authModel);
                }}
                errorMessage={errorMessage}
                loading={thorEventViewStore.progressFlags.settingBackToReview}
                disabled={thorEventViewStore.progressFlags.settingBackToReview}
                onBack={() => {
                    setIsShowReAuthenticate(false);
                    setIsModalOpen(true);
                }}
            />
        </>
    );
};
