import { ListCustomerUsersRequest } from "api/models/userManagement/userManagementApi";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxBackButton } from "components/ReusableComponents/LynxBackButton/LynxBackButton";
import PreventLeavePageUnsavedData from "components/ReusableForms/helper-components/PreventLeavePageUnsavedData";
import { Form, Formik } from "formik";
import { getPermissionKey } from "helpers/permissionHelpers";
import { isGuidValid } from "helpers/typeValidationHelpers";
import _ from "lodash";
import { observer } from "mobx-react";
import { StabilityFormStatus } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import {
    formikModelHelpers,
    formikModels,
    ScrollToFirstFormFieldError,
    SetApiValidationErrors,
    stabilityFormValidationSchema,
} from "validation";
import ProductFieldsArea from "../FormFieldsAreas/ProductFieldsArea";
import { StabilityFormFieldsArea } from "../FormFieldsAreas/StabilityFormFieldsArea";
import TemperatureRangesArea from "../FormFieldsAreas/TemperatureRangesArea";
import { stabilityFormStyles } from "../StabilityFormStyles";
import StabilityFormAuthModal from "./StabilityFormAuthModal";

export enum StabilityFormMode {
    CREATE = "create",
    EDIT = "edit",
    NEW_VERSION = "new_version",
}

export interface StabilityFormDetailsProps {
    mode: StabilityFormMode;
}

// TODO: need to revisit permission check logic - if permission check will loaded too long - page will be empty for this time
export const StabilityFormDetails = observer(({ mode }: StabilityFormDetailsProps) => {
    const { stabilityFormId } = useParams();
    const classes = stabilityFormStyles();
    const [initialValues, setInitialValues] = useState<formikModels.StabilityFormFormikModel | null>(null);

    const { stabilityFormStore, identityStore, userStore, permissionsStore, customerDataStore } = useStore();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isEditing = mode === StabilityFormMode.EDIT;
    const isNewVersion = mode === StabilityFormMode.NEW_VERSION;

    const key = getPermissionKey(
        `${actions.customer.tor.stabilityForms.modify}::customer`,
        identityStore.currentCustomer.id
    );
    const modifyPermissionStatus = permissionsStore.getPermissionStatus(key);

    const waitingForPermissionCheck =
        modifyPermissionStatus === undefined || modifyPermissionStatus === MarvelPermissionStatus.Loading;

    const accessDenied = identityStore.isSystemSpace || modifyPermissionStatus === MarvelPermissionStatus.Deny;
    const isValidGuid = isGuidValid(stabilityFormId);

    const setStabilityFormFormikModel = async () => {
        if (isEditing || isNewVersion) {
            if (stabilityFormId) {
                await stabilityFormStore.getStabilityFormToUpdate(identityStore.currentCustomer.id, stabilityFormId);

                if (stabilityFormStore.stabilityFormToUpdate) {
                    setInitialValues(
                        formikModelHelpers.convertToStabilityFormFormikModel(stabilityFormStore.stabilityFormToUpdate)
                    );
                }
            }
        } else {
            setInitialValues(formikModelHelpers.getBlankStabilityFormFormikModel());
        }
    };

    useEffect(() => {
        if (!!stabilityFormId && !isValidGuid) {
            return navigate(routes.pageNotFound);
        }

        if (accessDenied) {
            navigate(identityStore.startPageAvailable);
        }

        if (
            stabilityFormStore.stabilityFormToUpdate &&
            isNewVersion &&
            stabilityFormStore.stabilityFormToUpdate!.status !== StabilityFormStatus.Effective
        ) {
            navigate(identityStore.startPageAvailable);
        }
    }, [stabilityFormId, accessDenied, stabilityFormStore.progressFlags.loadingStabilityForm]);

    useEffect(() => {
        if (accessDenied || waitingForPermissionCheck) {
            return;
        }

        // calling async funcs without await as they are independent from each other
        customerDataStore.loadCustomerProductSupportInfo({ customerId: identityStore.currentCustomer.id });

        customerDataStore.loadRegions({ customerId: identityStore.currentCustomer.id });

        setStabilityFormFormikModel();

        const request: ListCustomerUsersRequest = {
            pageNumber: 1,
            pageSize: 5,
            customerId: identityStore.currentCustomer.id,
        };
        userStore.loadCustomerUsers(request);
    }, [modifyPermissionStatus]);

    // clenup effect
    useEffect(() => {
        return () => {
            stabilityFormStore.resetStabilityFormData();
        };
    }, []);

    const loading =
        stabilityFormStore.progressFlags.loadingStabilityForm ||
        customerDataStore.progressFlags.loadRegions ||
        customerDataStore.progressFlags.loadProductSupportInfo;

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>
                        {isEditing
                            ? `Edit ${stabilityFormStore.stabilityFormToUpdate?.productName ?? ""} Stability Form`
                            : isNewVersion
                            ? "New Version"
                            : "New Stability Form"}
                    </title>
                </Helmet>
                {loading && <FullScreenLoadingIndicator />}
                {!loading && (
                    <>
                        <LynxBackButton path={routes.stabilityForms} title="Stability Forms List" />
                        {!!initialValues && (
                            <Formik
                                initialValues={_.cloneDeep(initialValues)}
                                validationSchema={stabilityFormValidationSchema}
                                validateOnBlur={false}
                                validateOnChange={false}
                                enableReinitialize={true}
                                onSubmit={() => setIsModalOpen(true)}
                            >
                                <Form id="stabilityForm">
                                    <PreventLeavePageUnsavedData />

                                    <StabilityFormFieldsArea mode={mode} />

                                    <ProductFieldsArea />

                                    <TemperatureRangesArea />

                                    <StabilityFormAuthModal
                                        isModalOpen={isModalOpen}
                                        setIsModalOpen={setIsModalOpen}
                                        mode={mode}
                                    />

                                    <ScrollToFirstFormFieldError />

                                    <SetApiValidationErrors storeErrors={stabilityFormStore.formErrors} />
                                </Form>
                            </Formik>
                        )}
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
