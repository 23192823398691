import { Grid } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { useStore } from "store/StoreConfigs";
import { assignUserCommentValidationSchema } from "validation";
import { assignUserModalStyles } from "./AssignUserModalStyles";
import { LynxSelectWithSearch } from "components/LynxComponents/LynxSelectWithSearch/LynxSelectWithSearch";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { AssignUserModel } from "models/commonModels";
import { LynxModal } from "../LynxModal/LynxModal";

export interface AssignUserModalProps {
    loading: boolean;
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    modalWindowHeader: string;
    additionalText: string;
    assignFunc: (userId: string, comment: string) => void;
}

export const AssignUserModal = observer((props: AssignUserModalProps) => {
    const { userStore, identityStore } = useStore();
    const { loading: isLoading, isModalOpen, setIsModalOpen, modalWindowHeader, additionalText, assignFunc } = props;

    const classes = assignUserModalStyles();
    const formRef = useRef<FormikProps<AssignUserModel>>(null);

    const getCustomerUsers = (value?: string | undefined) => {
        userStore.loadCustomerUsers({
            pageNumber: 1,
            pageSize: 5,
            customerId: identityStore.currentCustomer.id,
            searchValue: value,
            searchParameter: ["FullName"],
        });
    };

    const loading = isLoading || userStore.progressFlags.loadingUsersForDropdown;

    const handleSubmit = (values: AssignUserModel) => {
        if (!values.id) {
            return;
        }

        assignFunc(values.id, values.content ?? "");
    };

    useEffect(() => {
        getCustomerUsers();
    }, []);

    return (
        <Formik
            initialValues={{ content: "", id: "", displayName: "" }}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={assignUserCommentValidationSchema}
            onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.resetForm();
            }}
            innerRef={formRef}
        >
            {({ values, setFieldValue }) => (
                <Grid item>
                    <LynxModal
                        open={isModalOpen}
                        secondaryButtonTitle="Cancel"
                        primaryButtonTitle="Assign"
                        header={modalWindowHeader}
                        onConfirm={() => formRef.current?.handleSubmit()}
                        onClose={() => setIsModalOpen(false)}
                        loading={loading}
                        disabledCancel={loading}
                        disabledSubmit={loading || !values?.id}
                    >
                        <Form>
                            <LynxSelectWithSearch
                                value={values?.id && values.id !== "" ? values : null}
                                disablePortal
                                forcePopupIcon
                                isSearchWithAutocomplete
                                disablePopupIconInteractions
                                freeSolo={false}
                                disableClearable={false}
                                multiple={false}
                                triggerSearchOnClear
                                options={userStore.customerUsers.map((x) => ({
                                    id: x.id,
                                    displayName: `${x.firstName} ${x.lastName}`,
                                }))}
                                search={getCustomerUsers}
                                placeholder="Search person to assign"
                                loading={loading}
                                onChange={(e, value) => {
                                    setFieldValue("id", value?.id);
                                    setFieldValue("displayName", value?.displayName);
                                }}
                                label={<LabelWithRequiredSymbol label="Search by name" />}
                                getOptionLabel={(option) => (option.id === "" ? "" : option.displayName)}
                                getOptionSelected={(option, value) => option.id === value.id}
                            />

                            <Grid item>
                                <LynxTypography variant="body-s" color="neutral400">
                                    Comment
                                </LynxTypography>
                                <LynxInputForm
                                    className={classes.textArea}
                                    name="content"
                                    multiline
                                    minRows={6}
                                    placeholder="Leave your comments here..."
                                />
                                <Grid item>
                                    <LynxTypography variant="body-s" color="neutral400">
                                        {additionalText}
                                    </LynxTypography>
                                </Grid>
                            </Grid>
                        </Form>
                    </LynxModal>
                </Grid>
            )}
        </Formik>
    );
});
