import { productDecisionsText } from "lynxConstants";
import { AssessmentDecision, RecommendedMessagesModel } from "models/thorEvents/eventModels";

export function getAssessmentDecisionText(
    decision: AssessmentDecision | null,
    isPersonDecision: boolean = false
): string {
    switch (decision) {
        case AssessmentDecision.NoImpactOnProductQuality:
            return productDecisionsText.productCanBeUsed;
        case AssessmentDecision.ExistingImpactOnProductQuality:
            return productDecisionsText.productCannotBeUsed;
        case AssessmentDecision.AdditionalReviewRequired:
            return productDecisionsText.additionalReviewRequired;
        default:
            return isPersonDecision ? productDecisionsText.noDecision : productDecisionsText.noAssessment;
    }
}

export function getAssessmentRecommendationText(messages: RecommendedMessagesModel): string[] {
    const groupedMessages = [];

    messages.expirationDate && groupedMessages.push(messages.expirationDate);
    messages.freezeHeatCycles && groupedMessages.push(messages.freezeHeatCycles);
    messages.transportationModeScenario && groupedMessages.push(messages.transportationModeScenario);
    messages.highLowExcursion && groupedMessages.push(messages.highLowExcursion);
    messages.excursion && groupedMessages.push(messages.excursion);

    return groupedMessages;
}
