import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/rangeHelpers";
import { breakLines } from "helpers/stringHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { AssessmentDetailsSection } from "../AssessmentDetailsSection";
import { assessmentDetailsStyles } from "../AssessmentDetailsStyles";
import { AssessmentTemperatureRangesProps } from "./AssessmentTemperatureRangesProps";

export const AssessmentTemperatureRanges = observer(({ ranges }: AssessmentTemperatureRangesProps) => {
    const classes = assessmentDetailsStyles();

    const tableHeaders = [
        "Temperature\nRange",
        "Product Stability\nBudget",
        "Batch Remaining\nStability Budget",
        "Event Excursion\nTime",
        "Product Remaining\nStability Budget",
    ];

    return (
        <AssessmentDetailsSection title="Temperature Ranges">
            <Table>
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((x) => (
                            <TableCell key={x} className={classes.temperatureTableHeaderCell}>
                                <LynxTypography variant="body-s" color="neutral400">
                                    {breakLines(x)}
                                </LynxTypography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ranges
                        .slice()
                        .sort((a, b) => a.position - b.position)
                        .map((range) => (
                            <TableRow key={range.id} className={classes.temperatureTableRows}>
                                <TableCell className={clsx(classes.temperatureTableRowCell)}>
                                    <div className={classes.temperatureRangeCell}>
                                        <div className={classes.temperatureRangeCellIconContainer}>
                                            {range.excursionDuration > 0 && <LynxIcon name="triangleWarning" />}
                                        </div>
                                        <LynxTypography variant="body-medium">
                                            {formatTemperatureRange(
                                                range.lowerLimit,
                                                range.upperLimit,
                                                range.lowerInclusive,
                                                range.upperInclusive
                                            )}
                                        </LynxTypography>
                                    </div>
                                </TableCell>
                                <TableCell className={classes.temperatureTableRowCell}>
                                    <LynxTypography>
                                        {formatDurationToString(range.baseline, range.baseline !== null)}
                                    </LynxTypography>
                                </TableCell>
                                <TableCell className={classes.temperatureTableRowCell}>
                                    <LynxTypography>{formatDurationToString(range.batchRsb)}</LynxTypography>
                                    {range.rsbAdjustment !== null && (
                                        <LynxTypography variant="body-xs">
                                            {`Adjusted (${range.rsbAdjustment > 0 ? "+" : ""}${formatDurationToString(
                                                range.rsbAdjustment
                                            )})`}
                                        </LynxTypography>
                                    )}
                                </TableCell>
                                <TableCell className={classes.temperatureTableRowCell}>
                                    <LynxTypography>{formatDurationToString(range.excursionDuration)}</LynxTypography>
                                </TableCell>
                                <TableCell className={classes.temperatureTableRowCell}>
                                    <LynxTypography>{formatDurationToString(range.productRsb)}</LynxTypography>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </AssessmentDetailsSection>
    );
});
