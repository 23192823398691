import { Page } from "models/shared/Page";

export enum AssessmentDecision {
    NoImpactOnProductQuality = 0,
    ExistingImpactOnProductQuality = 1,
    AdditionalReviewRequired = 2,
}

export enum AssessmentStatus {
    PendingReview = 0,
    PendingDecision = 1,
    EvaluationCompleted = 2,
}

export enum EventProcessType {
    Commercial = 0,
    Clinical = 1,
}

export enum EventStatus {
    New = 0,
    PendingReview = 1,
    PendingApproval = 2,
    Closed = 3,
    AutoReleased = 4,
}

export enum EventType {
    Transportation = 0,
    Site = 1,
}

export enum ExcursionSource {
    TemperatureRecordingDevice = 0,
    Manual = 1,
}

export enum RsbAdjustmentAction {
    Reduce = 0,
    Add = 1,
}

export enum YesNoType {
    Yes = "yes",
    No = "no",
}

export enum TrueFalseType {
    False = "false",
    True = "true",
}

export interface AssessmentModel {
    id: string;
    status: AssessmentStatus;
    productDisplayId: string | null;
    productName: string | null;
    productDoseFormName: string | null;
    productDosage: number | null;
    productDosageUomName: string | null;
    storageLowerInclusive: boolean;
    storageUpperInclusive: boolean;
    storageLowerLimit: number | null;
    storageUpperLimit: number | null;
    productStorageTypeName: string | null;
    productCategoryName: string | null;
    productPresentationName: string | null;
    productExpiryPeriodInHours: number | null;
    productSpec: string | null;
    productFreezeCycleLimit: number | null;
    productHeatCycleLimit: number | null;
    stabilityFormId: string | null;
    stabilityFormNumber: string | null;
    batchId: string;
    batchNumber: string;
    manufacturingStage: string | null;
    batchExpirationDate: string | null;
    quantity: number | null;
    handlingUnitNumber: string | null;
    handlingUnitIsVirtual: boolean | null;
    recommendedDecision: AssessmentDecision | null;
    recommendationMessages: RecommendedMessagesModel | null;
    isWithFirstEconomicCustomer: boolean | null;
    decision: AssessmentDecision | null;
    flowId: string | null;
    excursionStartedAt: string | null;
    totalExcursionDuration: number | null;
    lowTemperature: number | null;
    highTemperature: number | null;
    totalSpikesCount: number | null;
    freezeCyclesCount: number | null;
    heatCyclesCount: number | null;
    reviewerUserId: string | null;
    reviewerFirstName: string | null;
    reviewerLastName: string | null;
    reviewedDate: string | Date | null;
    qaUserId: string | null;
    qaFirstName: string | null;
    qaLastName: string | null;
    completedDate: Date | string | null;
    justification: string | null;
    devices: string[];
    impacts: ImpactOnRangeModel[];
    flows?: Flow[];
    historicalEvents?: HistoricalEventModel[];
    reviewerDecision: AssessmentDecision | null;
    comments: string | null;
}

export interface RecommendedMessagesModel {
    excursion: string;
    expirationDate: string | null;
    freezeHeatCycles: string | null;
    transportationModeScenario: string | null;
    transportationModeAllowanceRules: string | null;
    highLowExcursion: string | null;
}

export interface ImpactOnRangeModel {
    id: string;
    lowerInclusive: boolean;
    upperInclusive: boolean;
    lowerLimit: number | null;
    upperLimit: number | null;
    batchRsb: number;
    excursionDuration: number;
    totalExcursionDuration: number | null;
    totalExcursionDurationNoBatchTracking: number | null;
    productRsb: number;
    productRsbNoBatchTracking: number;
    baseline: number | null;
    rsbAdjustment: number | null;
    totalRsbAdjustment: number | null;
    position: number;
}

export interface HistoricalImpactModel {
    impactId: string;
    lowerInclusive: boolean;
    upperInclusive: boolean;
    lowerLimit: number | null;
    upperLimit: number | null;
    excursionDuration: number;
    batchRsbAfter: number;
    productRsb: number;
    position: number;
    rsbAdjustment: number | null;
}

export interface HistoricalEventModel {
    eventId: string;
    eventDisplayId: number;
    eventStatus: EventStatus;
    assessmentStatus: AssessmentStatus;
    batchNumber: string;
    manufacturingStage: string | null;
    flowId: string | null;
    flowFullName: string | null;
    impacts: HistoricalImpactModel[];
    isWithFirstEconomicCustomer: boolean | null;
    decision: AssessmentDecision | null;
    qaUserId: string | null;
    qaFirstName: string | null;
    qaLastName: string | null;
    assessmentCompletedDate: string | null;
    eventType: EventType;
    eventCreatedAt: string;
    recommendedDecision: AssessmentDecision;
    recommendationMessages: RecommendedMessagesModel;
    isRelevant: boolean;
    isOnRelevantPath: boolean | null;
    isCurrent: boolean;
}

export interface Flow {
    id: string;
    region: string;
    name: string;
    steps: string;
    remainingStabilityBudget: number;
}

export interface EventIdModel {
    id: string;
    displayId: string;
}

export interface EventDetails {
    id: string;
    displayId: number;
    type: EventType;
    status: EventStatus;
    processType: EventProcessType;
    excursionSource: ExcursionSource;
    createdAt: string;
    createdBy: string;
    closedAt: string | Date | null;
    eventCodeIds: string[];
    priorityId: string | null;
    timezone: string;
    dueDate: string | Date | null;
    quarantineDate: string | Date | null;
    reviewerUserId: string | null;
    reviewerFirstName: string | null;
    reviewerLastName: string | null;
    qaUserId: string | null;
    qaFirstName: string | null;
    qaLastName: string | null;
    minTemperature: number | null;
    maxTemperature: number | null;
    totalExcursionDuration: number;
    totalHighExcursionDuration: number | null;
    totalLowExcursionDuration: number | null;
    conditioningLeft: string | null;
    conditioningRight: string | null;
    conditioningAppliedDt: string | null;
    conditioningAppliedBy: string | null;
    conditioningAppliedByFirstName: string | null;
    conditioningAppliedByLastName: string | null;
    conditioningNote: string | null;
    lowerInclusive: boolean;
    upperInclusive: boolean;
    lowerLimit: number | null;
    upperLimit: number | null;
    hasTransportationConditions: boolean;
    manualExcursionDeviceIds: string | null;
    excursionSegments: ExcursionSegment[];
    assessments: AssessmentModel[];
    handlingUnits: string[];
    openedPreviousEvents: EventIdModel[];
    deliveryNumber: string | null;
    orderNumbers: string[] | null;
    shippingDate: string | Date | null;
    deliveryDate: string | Date | null;
    origin: EventLocationModel;
    destination: EventLocationModel;
    site: EventLocationModel;
    shipperType: string | null;
    transportationModeName: string | null;
    transportationServiceProviderName: string | null;
    logisticsServiceProviderName: string | null;
    laneNumber: string | null;
    laneStatus: string | null;
    regionName: string | null;
}

export interface HandlingUnit {
    displayId: string;
    deviceDisplayIds: string[];
}

export interface DeviceData {
    deviceId: string;
    serialNumber: string;
    dataPoints: DataPoint[];
    startupDelay: string | null;
    interval: string | null;
    deviceReadOn: Date | null;
    deviceReadBy: string | null;
    notes: string | null;
    monitorStartTime: Date | null;
    monitorStopTime: Date | null;
    activationEnergy: number | null;
    rawStatistics: DeviceStatistics | null;
    conditionedStatistics: DeviceStatistics | null;
    hasAlarm: boolean | null;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
}

export interface DeviceStatistics {
    firstPointTimestamp: Date | null;
    lastPointTimestamp: Date | null;
    tripDuration: string | null;
    highExtremeTemperature: number | null;
    highExtremeTemperatureTimestamp: Date | null;
    lowExtremeTemperature: number | null;
    lowExtremeTemperatureTimestamp: Date | null;
    meanTemperature: number | null;
    meanKineticTemperature: number | null;
    standardDeviation: number | null;
    spikesCount: number | null;
    cumulativeExcursionTime: string | null;
    pointsCount: number | null;
}

export interface DataPoint {
    timestamp: number;
    value: number;
}

export interface DeviceDataSource {
    id: number;
    name: string;
}

export interface EventListItem {
    id: string;
    displayId: number;
    type: EventType;
    createdAt: Date;
    dueDate?: Date;
    priorityId: string;
    status: EventStatus;
    reviewerUserId: string;
    reviewerFirstName: string;
    reviewerLastName: string;
    qaUserId: string;
    qaFirstName: string;
    qaLastName: string;
    deliveryNumber: string;
    originName: string;
    destinationName: string;
    products: ProductWithRelatedBatches[];
    timezone: string;
}

export interface ProductWithRelatedBatches {
    productFullName: string;
    batchesWithStage: BatchWithStageModel[];
}

export interface BatchWithStageModel {
    batchNumber: string;
    manufacturingStage: string;
}

export interface EventAttachment {
    id: string;
    eventId: string;
    uploadedBy: string;
    createdAt: Date;
    bucketFileName: string;
    fileName: string;
    contentType: string;
}

export interface EventImpact {
    eventId: string;
    durationInHours: number;
}

export interface ExcursionSegment {
    start: Date;
    end: Date;
    duration: number;
    adjacentToStart: boolean;
    adjacentToEnd: boolean;
    interval: Interval;
}

export interface Interval {
    lower: number;
    upper: number;
}

export interface LaneInformation {
    laneNumber: string;
    transportationMode: string;
    dms: string;
    status: string;
    carrier: string;
    shipper: string;
    temperature: string;
    originAddress: string;
    originCity: string;
    originState: string;
    originCountry: string;
    destinationAddress: string;
    destinationCity: string;
    destinationState: string;
    destinationCountry: string;
}

export interface EventLocationModel {
    id: string | null;
    code: string | null;
    name: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    city: string | null;
    country: string | null;
    postalCode: string | null;
    region: string | null;
    site: string | null;
    state: string | null;
}

export interface LocationDescriptionModel {
    id: string;
    code: string;
    codePriority: number;
    name: string;
    addressLine1: string | null;
    addressLine2: string | null;
    city: string | null;
    country: string | null;
    postalCode: string | null;
    region: string | null;
    site: string | null;
    state: string | null;
    physicalId: string;
}

export interface LocationInfo {
    id: string;
    code: string;
    name: string;
}

export interface MonitorTemperatures {
    label: string;
    data: MonitorTemperaturePoints[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
}

export interface MonitorTemperaturePoints {
    x: Date;
    y: number;
}

export interface DeliveryInformation {
    id: string | null;
    deliveryNumber: string | null;
    orderNumbers: string[];
    laneNumber: string | null;
    shipperType: string | null;
    transportationModeId: string | null;
    transportationServiceProviderName: string | null;
    logisticsServiceProviderName: string | null;
    lowerInclusive: boolean;
    upperInclusive: boolean;
    lowerLimit: number | null;
    upperLimit: number | null;
    origin: LocationInfo | null;
    destination: LocationInfo | null;
}

export interface TransportationMode {
    id: string;
    name: string;
}

export interface EventCode {
    id: string;
    name: string;
}

export interface DeviceModel {
    id: string;
    serialNumber: string;
}

export interface CalculatedExcursion {
    minTemperature: number | null;
    maxTemperature: number | null;
    startedAt: string | null;
    endedAt: string | null;
    totalDuration: number | null;
    totalLowDuration: number | null;
    totalHighDuration: number | null;
}

export interface DuplicatedDeviceEvents {
    id: string;
    displayId: string;
    deliveryNumber: string;
    devices: DeviceModel[];
}

export interface HistoricalEventsPage<T> extends Page<T> {
    relevantEventsCount: number;
    relevantEventsPages: number;
    manufacturingPlantFound: boolean | null;
    allDeliveriesHaveOriginAndDestination: boolean | null;
    batchTrackingEnabledOnBatchLevel: boolean | null;
}

export interface RangeModel {
    lowerLimit: number | null;
    lowerInclusive: boolean;
    upperLimit: number | null;
    upperInclusive: boolean;
}
