import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ListStabilityFormsRequest } from "api/models/stabilityForms/stabilityFormsApi";
import clsx from "clsx";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import FiltersResultEmptyState from "components/ReusableComponents/FiltersOrSearchResultEmptyState";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { prepareFilterOptionsForRequest } from "helpers/prepareFilterOptionsForRequest";
import { getPermissionKey } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormsStyles } from "./StabilityFormStyles";
import { ListPageHeader } from "components/ReusableComponents/ListPageHeader/ListPageHeader";
import { FixedHeaderRenderer } from "components/ReusableComponents/FixedHeaderRenderer";
import { withFixed } from "helpers/withFixed";
import { commonConstants, noFilterResultMessage, promptTooltipMessages } from "lynxConstants";
import { PaginationArea } from "models/shared/Page";
import { StabilityFormStatusChip } from "components/ThorEvents/utilityComponents/StabilityFormStatus";
import { StabilityFormStatus } from "models/productAndStabilityForm/productAndStabilityFormModels";

export const StabilityFormsView = observer(() => {
    const { stabilityFormStore, identityStore, userStore, permissionsStore, commonStore } = useStore();
    const navigate = useNavigate();
    const classes = stabilityFormsStyles();

    const tableHeaders: { title: string; width: number }[] = [
        { title: "Form Number", width: 14 },
        { title: "Product Name", width: 14 },
        { title: "Dose Form/Dosage", width: 20 },
        { title: "Add. Info", width: 8 },
        { title: "Effective Date", width: 13 },
        { title: "Version", width: 7 },
        { title: "Assignees", width: 8 },
        { title: "Status", width: 16 },
    ];

    const loadForms = () => {
        const request: ListStabilityFormsRequest = {
            customerId: identityStore.currentCustomer.id,
            pageNumber: stabilityFormStore.currentPage,
            pageSize: stabilityFormStore.pageSize,
            assignees: prepareFilterOptionsForRequest(stabilityFormStore.formAssignedSelectedFilters),
            formStatus: prepareFilterOptionsForRequest(stabilityFormStore.formStatusSelectedFilters),
            searchValue: stabilityFormStore.searchInputTrimmed,
            searchParameter: stabilityFormStore.searchParameter,
        };

        stabilityFormStore.loadStabilityForms(request);
    };

    const permissionKey = getPermissionKey(actions.customer.tor.stabilityForms.view, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    useEffect(() => {
        if (identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [identityStore.isSystemSpace, identityStore.startPageAvailable]);

    const viewDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.viewDetails,
        identityStore.currentCustomer.id
    );

    const modifyPermissionKey = getPermissionKey(
        `${actions.customer.tor.stabilityForms.modify}::customer`,
        identityStore.currentCustomer.id
    );

    useEffect(() => {
        if (permissionStatus !== MarvelPermissionStatus.Allow) {
            return;
        }

        loadForms();
    }, [permissionStatus, stabilityFormStore.currentPage, stabilityFormStore.pageSize]);

    if (permissionStatus !== MarvelPermissionStatus.Allow && !commonStore.showGeneralErrorPage) {
        return <FullScreenLoadingIndicator />;
    }

    const title = `Stability Forms (${stabilityFormStore.totalCount})`;

    const renderTableHeader = (isFixed: boolean) => (
        <TableHead>
            <TableRow className={classes.headerRow}>
                {tableHeaders.map((header) => (
                    <TableCell
                        className={withFixed(clsx(classes.cellBorderBottom, classes.headerCell), isFixed)}
                        key={header.title}
                        width={`${header.width}%`}
                    >
                        <LynxTypography color="neutral400" variant="h3">
                            {header.title}
                        </LynxTypography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    const tooltipMessage =
        permissionsStore.getPermissionStatus(modifyPermissionKey) !== MarvelPermissionStatus.Allow
            ? promptTooltipMessages.accessDenied
            : promptTooltipMessages.empty;

    const loading = stabilityFormStore.progressFlags.loadingStabilityForms;

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <ListPageHeader
                    title={title}
                    filters={[
                        {
                            options: userStore.customerUsers.map((x) => ({
                                displayName: `${x.firstName} ${x.lastName}`,
                                value: x.id,
                            })),
                            selectedOptions: stabilityFormStore.formAssignedSelectedFilters,
                            label: "Assigned",
                            onOptionsChange: stabilityFormStore.setAssignedFilter,
                            isAssignedUsersFilter: true,
                            optionsLoading: userStore.progressFlags.loadingUsersForDropdown,
                        },
                        {
                            options: [
                                { displayName: "Draft", value: StabilityFormStatus.Draft.toString() },
                                {
                                    displayName: "Pending Approval",
                                    value: StabilityFormStatus.PendingApproval.toString(),
                                },
                                { displayName: "Effective", value: StabilityFormStatus.Effective.toString() },
                                { displayName: "Deactivated", value: StabilityFormStatus.Deactivated.toString() },
                            ],
                            label: "Status",
                            selectedOptions: stabilityFormStore.formStatusSelectedFilters,
                            onOptionsChange: stabilityFormStore.setFormStatusFilter,
                            optionsLoading: false,
                        },
                    ]}
                    request={loadForms}
                    resetFilters={stabilityFormStore.resetAllFilters}
                    search={{
                        searchValue: stabilityFormStore.searchValue,
                        setSearchValue: stabilityFormStore.setSearchValue,
                        searchParameterProps: {
                            type: "product",
                            parameter: stabilityFormStore.searchParameter,
                            setSearchParameter: stabilityFormStore.setSearchParameter,
                        },
                    }}
                    titleButtonProps={{
                        text: "Add Stability Form",
                        onClick: () => navigate(routes.stabilityFormsCreate),
                        disabled: !permissionsStore.hasPermission(modifyPermissionKey),
                        loading: permissionsStore.permissionLoading(modifyPermissionKey),
                        tooltip: tooltipMessage,
                    }}
                />

                <div className={classes.tableContainer}>
                    <FixedHeaderRenderer level="third" topMargin={-100}>
                        {!loading && stabilityFormStore.stabilityForms.length > 0 && (
                            <div className={classes.fixedTableContainer}>
                                <Table className={classes.fixedTable}>{renderTableHeader(true)}</Table>
                            </div>
                        )}
                    </FixedHeaderRenderer>

                    {loading && <FullScreenLoadingIndicator />}

                    {!loading && stabilityFormStore.stabilityForms.length === 0 && (
                        <FiltersResultEmptyState message={noFilterResultMessage} />
                    )}

                    {!loading && stabilityFormStore.stabilityForms.length > 0 && (
                        <>
                            <Table className={classes.table}>
                                {renderTableHeader(false)}
                                <TableBody>
                                    {stabilityFormStore.stabilityForms.map((stabilityForm) => (
                                        <React.Fragment key={stabilityForm.id}>
                                            <TableRow className={classes.tableRow}>
                                                <TableCell className={classes.cellBorderBottom} width="14%">
                                                    <LynxTypography variant="body-l" className={classes.fieldMaxWidth}>
                                                        <LynxRedirectLink
                                                            to={routes.stabilityFormDetails.to(stabilityForm.id)}
                                                            disabled={
                                                                !permissionsStore.hasPermission(
                                                                    viewDetailsPermissionKey
                                                                )
                                                            }
                                                            loading={permissionsStore.permissionLoading(
                                                                viewDetailsPermissionKey
                                                            )}
                                                        >
                                                            {stabilityForm.number}
                                                        </LynxRedirectLink>
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="15%">
                                                    <LynxTypography variant="body-l" className={classes.fieldMaxWidth}>
                                                        {stabilityForm.productName}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="20%">
                                                    <LynxTypography variant="body-l" className={classes.fieldMaxWidth}>
                                                        {stabilityForm.representations
                                                            .map(
                                                                (x) =>
                                                                    `${x.doseFormName} (${
                                                                        x.allDosagesFlag
                                                                            ? "All"
                                                                            : `${x.dosage} ${x.unitOfMeasureName}`
                                                                    })`
                                                            )
                                                            .join(", ")}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="8%">
                                                    <LynxTypography variant="body-l">
                                                        {stabilityForm.additionalInfo ?? commonConstants.emptyValue}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="10%">
                                                    <LynxTypography variant="body-l">
                                                        {stabilityForm.effectiveDate
                                                            ? dateToFormat(
                                                                  stabilityForm.effectiveDate,
                                                                  commonConstants.shortDateFormat,
                                                                  true
                                                              )
                                                            : commonConstants.emptyValue}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="7%">
                                                    <LynxTypography>{stabilityForm.version}</LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="10%">
                                                    <LynxTypography variant="body-l">
                                                        <Grid container>
                                                            <Grid item>
                                                                {stabilityForm.ownerEmail !== commonConstants.system ? (
                                                                    <LynxAvatarWithTooltip
                                                                        email={stabilityForm.ownerEmail}
                                                                        firstName={stabilityForm.ownerFirstName}
                                                                        lastName={stabilityForm.ownerLastName}
                                                                    />
                                                                ) : (
                                                                    commonConstants.systemPascalCase
                                                                )}
                                                            </Grid>
                                                            <Grid item>
                                                                {stabilityForm.approverEmail &&
                                                                    (stabilityForm.approverEmail !==
                                                                    commonConstants.system ? (
                                                                        <LynxAvatarWithTooltip
                                                                            email={stabilityForm.approverEmail}
                                                                            firstName={stabilityForm.approverFirstName}
                                                                            lastName={stabilityForm.approverLastName}
                                                                        />
                                                                    ) : (
                                                                        commonConstants.systemPascalCase
                                                                    ))}
                                                            </Grid>
                                                        </Grid>
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom} width="16%">
                                                    <StabilityFormStatusChip status={stabilityForm.status} />
                                                </TableCell>
                                            </TableRow>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>

                            {stabilityFormStore.stabilityForms.length > 0 && (
                                <ThorPagination
                                    page={stabilityFormStore.currentPage}
                                    pages={stabilityFormStore.totalPages}
                                    onPageChange={stabilityFormStore.moveToPage}
                                    isLastPage={stabilityFormStore.isLastPage}
                                    setPage={stabilityFormStore.setPage}
                                    area={PaginationArea.StabilityForms}
                                    setPageSize={stabilityFormStore.setPageSize}
                                />
                            )}
                        </>
                    )}
                </div>
            </main>
        </GeneralErrorComponentWrapper>
    );
});
