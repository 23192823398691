import { FormikContextType, useFormikContext } from "formik";
import { toFormikArray, toFormikString } from "validation";

interface LynxFormikContextType<T> extends FormikContextType<T> {
    setValueOrEmptyStr: (field: string, value: string | number | null | undefined, resetError?: boolean) => void;
    setValueOrEmptyArr: (field: string, value: Array<any> | null | undefined, resetError?: boolean) => void;
}

export const useLynxFormikContext = <T>(): LynxFormikContextType<T> => {
    const formik = useFormikContext<T>();
    const setValueOrEmptyStr = (field: string, value: string | number | null | undefined, resetError = false) => {
        formik.setFieldValue(field, toFormikString(value));

        if (resetError) {
            formik.setFieldError(field, undefined);
        }
    };

    const setValueOrEmptyArr = (field: string, value: Array<any> | null | undefined, resetError = false) => {
        formik.setFieldValue(field, toFormikArray(value));

        if (resetError) {
            formik.setFieldError(field, undefined);
        }
    };

    const result: LynxFormikContextType<T> = { ...formik, setValueOrEmptyStr, setValueOrEmptyArr };

    return result;
};
