import { createStyles, makeStyles, Theme } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

interface ManufacturingStageLabelProps {
    manufacturingStage?: string | null;
    bold?: boolean;
}

const manufacturingStageLabelStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            display: "inline",
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            borderRadius: theme.borderRadius,
            padding: "0 0.25rem",
            margin: "0.25rem",
        },
    })
);

const ManufacturingStageLabel = (props: ManufacturingStageLabelProps) => {
    const classes = manufacturingStageLabelStyles();

    return (
        <>
            {props.manufacturingStage && (
                <LynxTypography variant={props.bold ? "body-sbold" : "body-s"} className={classes.label}>
                    {props.manufacturingStage.trim()}
                </LynxTypography>
            )}
        </>
    );
};

export default ManufacturingStageLabel;
