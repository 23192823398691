import { lynxString, YupSchema } from "validation";
import { object } from "yup";

export const assignUserCommentValidationSchema = object<
    YupSchema<{
        content: string;
    }>
>({
    content: lynxString().max(1000),
});
