import { PageModel } from "api/models/sharedModels/PageModel";
import { CustomerLevelRequest } from "api/models/sharedModels/CustomerLevelRequest";
import {
    CustomerStatus,
    GroupStatus,
    OktaStatus,
    PermissionOverrideType,
    TokenVerificationStatus,
    UserStatus,
    UserType,
} from "models/userManagement/userManagementModels";
import { ErrorResponseWithPayload } from "../sharedModels/ResponseModels";
import { SearchModel } from "../sharedModels/SearchModel";

export interface CustomerDetailsSystemUserInfo {
    id: string;
    firstName: string;
    lastName: string;
    title: string | null;
}

export interface CreateCustomerRequest {
    name: string;
    email?: string;
    address?: string;
    systemUsers?: string[];
}
export interface EditCustomerRequest extends CustomerLevelRequest {
    fieldsToEdit: string[];
    name?: string;
    email?: string | null;
    address?: string | null;
    status?: string;
    addedSystemUsers?: string[];
    removedSystemUsers?: string[];
}
export interface GetCustomerDetailsRequest extends CustomerLevelRequest {}
export interface ListCustomersRequest extends PageModel {}
export interface CustomerDetailsResponse {
    id: string;
    name: string;
    email: string | null;
    address: string | null;
    status: CustomerStatus;
    systemUsers: CustomerDetailsSystemUserInfo[];
}
export interface GetCustomerConfigurationRequest extends CustomerLevelRequest {}
export interface CustomerConfigurationModel {
    batchTrackingEnabled: boolean;
    autoReleaseEnabled: boolean;
}
export interface CustomerListItemResponse {
    id: string;
    name: string;
    email: string | null;
    address: string | null;
    status: CustomerStatus;
}
export interface GroupApiPermissionInfo {
    id: string;
    overrideType: PermissionOverrideType;
}
export interface GroupApiRoleInfo {
    id: string;
    name: string;
}
export interface GroupApiUserInfo {
    id: string;
    firstName: string;
    lastName: string;
    title: string | null;
}

export interface CreateGroupRequest {
    name: string;
    roleId?: string;
    users?: string[];
    permissions?: GroupApiPermissionInfo[];
    isNotify?: boolean;
    isAutoReleasedNotify?: boolean;
    regions?: string[];
}

export interface CreateCustomerGroupRequest extends CustomerLevelRequest, CreateGroupRequest {}
export interface CreateSystemGroupRequest extends CreateGroupRequest {}

export interface EditGroupRequest {
    groupId: string;
    fieldsToEdit: string[];
    name?: string;
    status?: string;
    roleId?: string | null;
    addedUsers?: string[];
    removedUsers?: string[];
    permissionsToCheckAndIgnoreRole?: string[];
    permissionsToUncheckAndIgnoreRole?: string[];
    permissionsToManageByRole?: string[];
    isNotify?: boolean;
    isAutoReleasedNotify?: boolean;
    addedRegions?: string[];
}

export interface EditCustomerGroupRequest extends EditGroupRequest, CustomerLevelRequest {}
export interface EditSystemGroupRequest extends EditGroupRequest {}

export interface GetCustomerGroupDetailsRequest extends CustomerLevelRequest {
    groupId: string;
}
export interface GetSystemGroupDetailsRequest {
    groupId: string;
}
export interface ListCustomerGroupsRequest extends CustomerLevelRequest, PageModel, SearchModel<"group"> {}

export interface ListSystemGroupsRequest extends PageModel, SearchModel<"group"> {}

export interface GroupDetailsResponse {
    id: string;
    name: string;
    role: GroupApiRoleInfo | null;
    status: GroupStatus;
    allTenantsVisibility: boolean;
    readonlyPermissions: boolean;
    readonlyStatus: boolean;
    readonlyName: boolean;
    ensureAtLeastOneUser: boolean;
    users: GroupApiUserInfo[];
    permissions: GroupApiPermissionInfo[];
    isNotify: boolean;
    isAutoReleasedNotify: boolean;
    regions: string[];
}

export interface CustomerGroupDetailsResponse extends GroupDetailsResponse {}

export interface SystemGroupDetailsResponse extends GroupDetailsResponse {}

export interface GroupListItemResponse {
    id: string;
    name: string;
    status: GroupStatus;
    role: GroupApiRoleInfo | null;
    allTenantsVisibility: boolean;
    readonlyPermissions: boolean;
    readonlyStatus: boolean;
    readonlyName: boolean;
}

export interface CustomerGroupListItemResponse extends GroupListItemResponse {}

export interface SystemGroupListItemResponse extends GroupListItemResponse {}

export interface PermissionApiRequestItem {
    userId: string;
    permissionId: string;
    data?: object;
}

export interface EvaluatePermissionsRequest {
    permissions: PermissionApiRequestItem[];
}
export interface ListCustomerPermissionsRequest extends CustomerLevelRequest {}

export interface ListCustomerRolesRequest extends CustomerLevelRequest {}
export interface ListSystemPermissionsRequest {}
export interface ListSystemRolesRequest {}
export interface PermissionEvaluationResponse {
    request: PermissionApiRequestItem;
    allow: boolean;
}
export interface PermissionListItemResponse {
    id: string;
    name: string;
    section: string;
    description: string;
}
export interface RoleListItemResponse {
    id: string;
    name: string;
    permissions: PermissionListItemResponse[];
}
export interface UsersApiCustomerDetails {
    id: string;
    name: string;
    status: CustomerStatus;
    groups: UsersApiGroupDetails[];
}
export interface UsersApiGroupDetails {
    id: string;
    name: string;
}

export interface CreateUserRequest {
    id: string;
    firstName: string;
    lastName: string;
    title?: string;
    withPassword: boolean;
    password?: string;
    groups?: string[];
}

export interface CreateCustomerUserRequest extends CustomerLevelRequest, CreateUserRequest {}

export interface CreateSystemUserRequest extends CreateUserRequest {
    customers?: string[];
}

export interface EditUserRequest {
    userId: string;
    fieldsToEdit: string[];
    status?: UserStatus;
    firstName?: string;
    lastName?: string;
    title?: string | null;
    addedToGroups?: string[];
    removedFromGroups?: string[];
}

export interface EditCustomerUserRequest extends CustomerLevelRequest, EditUserRequest {}

export interface EditSystemUserRequest extends EditUserRequest {
    addedToCustomers?: string[];
    removedFromCustomers?: string[];
}

export interface GetCurrentUserDetailsRequest {}

export interface GetCustomerUserDetailsRequest extends CustomerLevelRequest {
    userId: string;
}
export interface GetSystemUserDetailsRequest {
    userId: string;
}

export interface ListUsersRequest extends SearchModel<"user"> {
    users?: string[];
    fromGroups?: string[];
    includeGroups?: boolean;
}

export interface ListCustomerUsersRequest extends CustomerLevelRequest, PageModel, ListUsersRequest {
    systemUsersOnly?: boolean;
}
export interface ListSystemUsersRequest extends PageModel, ListUsersRequest {}

export interface CurrentUserDetailsResponse {
    id: string;
    oktaId: string;
    firstName: string;
    lastName: string;
    title: string | null;
    type: UserType;
    status: UserStatus;
    allTenantsVisibility: boolean;
    customers: UsersApiCustomerDetails[];
    systemGroups: UsersApiGroupDetails[];
    termsApproved: boolean;
}

export interface UserDetailsResponse {
    id: string;
    oktaId: string;
    firstName: string;
    lastName: string;
    title: string | null;
    status: UserStatus;
    groups: UsersApiGroupDetails[];
}

export interface CustomerUserDetailsResponse extends UserDetailsResponse {
    isSystemUser: boolean;
}

export interface SystemUserDetailsResponse extends UserDetailsResponse {
    customers: Omit<UsersApiCustomerDetails, "groups">[];
}

export interface UserListItemResponse {
    id: string;
    firstName: string;
    lastName: string;
    title: string | null;
    status: UserStatus;
    groups: UsersApiGroupDetails[];
}

export interface CustomerUserListItemResponse extends UserListItemResponse {
    isSystemUser: boolean;
}

export interface SystemUserListItemResponse extends UserListItemResponse {}

export interface CustomerEventPriorityResponse {
    id: string;
    name: string;
    isDefault: boolean;
    color: string;
}

export interface CustomersDataWithSearch extends CustomerLevelRequest, PageModel {
    searchValue?: string;
}

export interface CustomerDeliveryBasedRequests extends CustomersDataWithSearch {
    deliveryId?: string;
}

export interface RecoveryTokenVerificationResponse {
    verificationStatus: TokenVerificationStatus;
    userId: string | null;
    firstName: string | null;
    lastName: string | null;
}

export type RecoveryTokenResponse =
    | RecoveryTokenVerificationResponse
    | ErrorResponseWithPayload<RecoveryTokenVerificationResponse>;

export interface UserOktaInfoResponse {
    id: string;
    firstName: string;
    lastName: string;
    oktaId: string;
    oktaStatus: OktaStatus;
}

export enum OktaAuthenticationStatus {
    Unauthenticated = "UNAUTHENTICATED",
    PasswordExpired = "PASSWORD_EXPIRED",
    Success = "SUCCESS",
    LockedOut = "LOCKED_OUT",
}

export interface AuthenticationResponseModel {
    authenticationStatus: { value: OktaAuthenticationStatus };
    sessionToken: string;
    errorMessage?: string;
    recoveryToken?: string;
}
