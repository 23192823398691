import { ButtonBase, Grid, InputLabel, MenuItem, Modal } from "@material-ui/core";
import clsx from "clsx";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import { ErrorList } from "../ErrorList/ErrorList";
import { PermissionSection } from "../PermissionSection/PermissionSection";
import { UserList } from "../UserList/UserList";
import { UserItem } from "../UserList/UserListProps";
import { formStyles } from "./FormStyles";

export const AddGroupForm = observer(() => {
    const classes = formStyles();
    const { identityStore, groupStore, customerDataStore, commonStore } = useStore();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState("");

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        groupStore.clearErrors();
        groupStore.addGroupModel[e.target.name as string] = e.target.value;
    };

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        groupStore.clearErrors();
        groupStore.addGroupModel[e.target.name as string] = e.target.value;
    };

    const handleUserSelectionChange = (newSelection: UserItem[]) => {
        groupStore.clearErrors();
        groupStore.addGroupModel.users = newSelection;
    };

    const handleRegionsChange = (e: any) => {
        groupStore.clearErrors();
        setError("");
        groupStore.addGroupModel.regions = e.target.value as string[];
        groupStore.addGroupModel.allCountriesAndRegions = false;
    };

    const handleIsNotifyChange = (e: any, checked: boolean) => {
        groupStore.clearErrors();
        groupStore.addGroupModel.isNotify = checked;
    };

    const handleIsAutoReleasedNotifyChange = (e: any, checked: boolean) => {
        groupStore.clearErrors();
        groupStore.addGroupModel.isAutoReleasedNotify = checked;
    };

    const handleIsAllCountryChange = (e: any, checked: boolean) => {
        groupStore.clearErrors();

        if (checked) {
            groupStore.addGroupModel.regions = customerDataStore.regions.map((region) => region.id);
        } else {
            groupStore.addGroupModel.regions = [];
        }
        groupStore.addGroupModel.allCountriesAndRegions = checked;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            !identityStore.isSystemSpace &&
            !groupStore.addGroupModel.allCountriesAndRegions &&
            groupStore.addGroupModel.regions.length === 0
        ) {
            setError("Please select at least one item from the list");
        } else {
            groupStore.addGroup(() => setModalOpen(true));
        }
    };

    useEffect(() => {
        groupStore.clearFormState();

        if (!identityStore.isSystemSpace) {
            groupStore.addGroupModel.allCountriesAndRegions = true;
            groupStore.addGroupModel.regions = customerDataStore.regions.map((region) => region.id);
        }

        groupStore.loadPermissionsForSelection();
        groupStore.loadRolesForSelection();
        groupStore.loadUsersForSelection();

        if (!identityStore.isSystemSpace && customerDataStore.regions.length === 0) {
            customerDataStore.loadRegions({ customerId: identityStore.currentCustomer.id });
        }
    }, []);

    useEffect(() => {
        if (!identityStore.isSystemSpace) {
            groupStore.addGroupModel.allCountriesAndRegions = true;
            groupStore.addGroupModel.regions = customerDataStore.regions.map((region) => region.id);
        }
    }, [customerDataStore.regions]);

    useEffect(() => {
        groupStore.populatePermissions(groupStore.addGroupModel, false);
    }, [groupStore.addGroupModel.roleId, groupStore.permissionsForSelection, groupStore.rolesForSelection]);

    const getRolesForDropdown = () => {
        return groupStore.rolesForSelection;
    };

    const getListOfSections = () => {
        // remove duplicates
        return groupStore.addGroupModel.permissions.map((x) => x.section).filter((x, i, self) => self.indexOf(x) === i);
    };

    return (
        <GeneralErrorComponentWrapper>
            <main className={clsx(classes.formContainer, classes.tabContainer)}>
                <Modal open={modalOpen} className={classes.modal}>
                    <div className={classes.modalContent}>
                        <LynxTypography>
                            Group &apos;{groupStore.addGroupModel.name}&apos; added successfully!
                        </LynxTypography>
                        <br />
                        <div className={classes.buttonsContainer}>
                            <LynxButton className={classes.formButton} onClick={() => setModalOpen(false)}>
                                Add another group
                            </LynxButton>
                            <LynxButton
                                variant="tertiary"
                                className={classes.formButton}
                                onClick={() => navigate("..")}
                            >
                                Back to group list
                            </LynxButton>
                        </div>
                    </div>
                </Modal>

                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item md={6} className={classes.leftSide}>
                            <LynxTypography className={classes.title} variant="h2">
                                Create new User Group
                            </LynxTypography>
                            <LynxInput
                                size="large"
                                name="name"
                                value={groupStore.addGroupModel.name}
                                label="Group Name"
                                onChange={handleInputChange}
                                formControlClassName={clsx(classes.fullWidthInput, classes.inputMargin)}
                            />

                            <div className={classes.inputMargin}>
                                <LynxTypography className={classes.formInputLabel}>Users</LynxTypography>
                                {groupStore.progressFlags.loadingUsersForSelection ? (
                                    <LoadingIndicator className={classes.loadingUsersIndicator} />
                                ) : (
                                    <UserList
                                        users={groupStore.usersForSelection}
                                        onSelectionChange={handleUserSelectionChange}
                                    />
                                )}
                            </div>

                            <ErrorList errors={groupStore.formErrors} className={classes.inputMargin} />
                            <div className={classes.buttonsContainer}>
                                <LynxButton
                                    className={classes.formButton}
                                    disabled={
                                        groupStore.progressFlags.loadingRolesForSelection ||
                                        groupStore.progressFlags.loadingPermissionsForSelection ||
                                        groupStore.progressFlags.loadingUsersForSelection ||
                                        groupStore.progressFlags.addGroupRequest
                                    }
                                    loading={groupStore.progressFlags.addGroupRequest}
                                    type="submit"
                                >
                                    Submit
                                </LynxButton>
                                <LynxButton
                                    variant="tertiary"
                                    className={classes.formButton}
                                    onClick={() => navigate("..")}
                                >
                                    Cancel
                                </LynxButton>
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <div style={{ padding: "20px 25px" }}>
                                <div className={classes.permissionsHeader}>
                                    <LynxTypography variant="h2">Permissions</LynxTypography>
                                    <ButtonBase
                                        className={classes.resetChangesButton}
                                        onClick={() => groupStore.resetToRoleDefaults()}
                                    >
                                        Reset to role defaults
                                    </ButtonBase>
                                </div>
                                <InputLabel id="create-group-role-label" className={classes.formInputLabel}>
                                    Role
                                </InputLabel>
                                <LynxSelect
                                    className={clsx(classes.fullWidthInput, classes.roleDropdown)}
                                    labelId="create-group-role-label"
                                    value={groupStore.addGroupModel.roleId}
                                    name="roleId"
                                    disabled={groupStore.progressFlags.loadingRolesForSelection}
                                    loading={groupStore.progressFlags.loadingRolesForSelection}
                                    onChange={handleSelectChange}
                                    renderValue={(value) =>
                                        value === "" ? (
                                            <i>None</i>
                                        ) : (
                                            groupStore.rolesForSelection.find((x) => x.id === value)?.name
                                        )
                                    }
                                    displayEmpty
                                >
                                    <MenuItem value={""}>
                                        <i>None</i>
                                    </MenuItem>
                                    {groupStore.rolesForSelection.map((x) => (
                                        <MenuItem key={x.id} value={x.id}>
                                            {x.name}
                                        </MenuItem>
                                    ))}
                                </LynxSelect>
                                <div>
                                    {groupStore.progressFlags.loadingPermissionsForSelection ? (
                                        <LoadingIndicator style={{ height: "150px" }} />
                                    ) : (
                                        getListOfSections().map((x) => (
                                            <PermissionSection
                                                operationType="add"
                                                sectionName={x}
                                                key={x}
                                                className={classes.permissionSection}
                                            />
                                        ))
                                    )}
                                </div>

                                {!identityStore.isSystemSpace && (
                                    <>
                                        <div className={classes.notificationsHeader}>
                                            <LynxTypography variant="h2">Notifications</LynxTypography>
                                        </div>

                                        <div className={classes.formPaddingBottom}>
                                            <LynxCheckBox
                                                className={classes.checkBoxLabel}
                                                checked={groupStore.addGroupModel.isNotify}
                                                onChange={handleIsNotifyChange}
                                                label={
                                                    <span>
                                                        Receive email when new Event is created
                                                        <span className={classes.optionalFont}></span>
                                                    </span>
                                                }
                                            />
                                        </div>

                                        {identityStore.customerConfiguration.autoReleaseEnabled && (
                                            <div className={classes.formPaddingBottom}>
                                                <LynxCheckBox
                                                    className={classes.checkBoxLabel}
                                                    checked={groupStore.addGroupModel.isAutoReleasedNotify}
                                                    onChange={handleIsAutoReleasedNotifyChange}
                                                    label={
                                                        <span>
                                                            Receive email when new Event is auto released
                                                            <span className={classes.optionalFont}></span>
                                                        </span>
                                                    }
                                                />
                                            </div>
                                        )}

                                        <div className={classes.formPaddingBottom}>
                                            <LynxCheckBox
                                                className={classes.checkBoxLabel}
                                                checked={groupStore.addGroupModel.allCountriesAndRegions}
                                                onChange={handleIsAllCountryChange}
                                                label={
                                                    <span>
                                                        All Countries and Regions
                                                        <span className={classes.optionalFont}></span>
                                                    </span>
                                                }
                                            />
                                        </div>

                                        <LynxTypography>Country and Region</LynxTypography>
                                        <LynxSelect
                                            multiple
                                            value={groupStore.addGroupModel.regions}
                                            onChange={handleRegionsChange}
                                            className={clsx(classes.fullWidthInput, classes.roleDropdown)}
                                            disabled={groupStore.addGroupModel.allCountriesAndRegions}
                                            loading={customerDataStore.progressFlags.loadRegions}
                                            displayEmpty
                                            renderValue={() =>
                                                groupStore.addGroupModel.regions.length === 0 ||
                                                groupStore.addGroupModel.allCountriesAndRegions ? (
                                                    <i>Choose Country and Region</i>
                                                ) : (
                                                    customerDataStore.regions
                                                        .filter((region) =>
                                                            groupStore.addGroupModel.regions.includes(region.id)
                                                        )
                                                        .map((region) => region.name)
                                                        .join(",")
                                                )
                                            }
                                        >
                                            {customerDataStore.regions.map((region) => (
                                                <MenuItem value={region.id} key={region.id}>
                                                    {region.name}
                                                </MenuItem>
                                            ))}
                                        </LynxSelect>
                                        {error && <span style={{ color: "red" }}>{error}</span>}
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </main>
        </GeneralErrorComponentWrapper>
    );
});
