import { NodeProps } from "@xyflow/react";
import { batchDistributionDiagramPageStyles, getGreyedOutClass } from "./BatchDistributionDiagramPageStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxLocationNode } from "./BatchDistributionDiagramTypes";
import { DummyNodeHandle } from "./DummyNodeHandle";
import { EventCard } from "./EventCard";
import clsx from "clsx";

export const LocationNode = (props: NodeProps<LynxLocationNode>) => {
    const classes = batchDistributionDiagramPageStyles();

    return (
        <>
            <div className={clsx(classes.locationNode, getGreyedOutClass(props.data))}>
                <LynxTypography variant="body-sbold-s" color="white">
                    {props.data.code}, {props.data.name}
                </LynxTypography>
                {props.data.events.length !== 0 && (
                    <div className={classes.locationNodeEventCardsContainer}>
                        {props.data.events.map((event) => (
                            <EventCard event={event} key={event.eventId} isHighlighted={props.data.isHighlighted} />
                        ))}
                    </div>
                )}
            </div>
            <DummyNodeHandle type="source" />
            <DummyNodeHandle type="target" />
        </>
    );
};
