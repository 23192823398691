import { useOktaAuth } from "@okta/okta-react";
import { userInactivityTimeLimitInMinutes } from "lynxConstants/commonConstants";
import { useEffect, useRef } from "react";
import { routes } from "routes";

export const AutoLogout = () => {
    const userActions = ["click", "keyup", "mousemove", "scroll"];
    const timeoutRef = useRef<number | null>(null);
    const { oktaAuth } = useOktaAuth();
    const logoutInactivityTime = userInactivityTimeLimitInMinutes * 60 * 1000; // 15 minutes

    const setUserInactivityTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = window.setTimeout(async () => {
            await oktaAuth.signOut({
                revokeAccessToken: true,
                revokeRefreshToken: true,
                postLogoutRedirectUri: `${window.location.origin}${routes.login}`,
            });
        }, logoutInactivityTime);
    };

    useEffect(() => {
        userActions.forEach((action) => window.addEventListener(action, setUserInactivityTimeout));
        setUserInactivityTimeout();

        return () => {
            userActions.forEach((action) => window.removeEventListener(action, setUserInactivityTimeout));

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return <></>;
};
