import { getPermissionKey } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useStore } from "store/StoreConfigs";
import { reportingStyles } from "./ReportingStyles";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LynxIcon } from "icons/LynxIcon";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { ReportType } from "api/models/reporting/reportingApi";
import dayjs from "dayjs";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { FixedHeaderRenderer } from "components/ReusableComponents/FixedHeaderRenderer";
import { withFixed } from "helpers/withFixed";
import clsx from "clsx";
import { reportsInfoText } from "lynxConstants/commonTexts";

export const Reporting = observer(() => {
    const { identityStore, permissionsStore, reportingStore } = useStore();
    const classes = reportingStyles();

    const navigate = useNavigate();
    const currentTimezone = dayjs.tz.guess();

    const permissionKey = getPermissionKey(actions.customer.tor.reporting.view, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    useEffect(() => {
        if (identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [identityStore.startPageAvailable]);

    const tableHeaders: { title: string; width: number }[] = [
        { title: "Report Name", width: 30 },
        { title: "Description", width: 50 },
        { title: "", width: 20 },
    ];

    const reportVariants = [
        {
            name: "Root Cause Analysis",
            description: "Review the reasons temperature excursions have occurred for the events in the past 90 days.",
            type: ReportType.EventRootCauseAnalysis,
        },
        {
            name: "Duration to Close",
            description: "Number of days from the time the event was created until the time it was closed.",
            type: ReportType.EventDurationToClose,
        },
        {
            name: "Final Product Decisions",
            description: "Completed product assessment with their final outcomes, product can or cannot be used.",
            type: ReportType.EventFinalProductDecisions,
        },
        {
            name: "Event Overdue",
            description: "Number of days past the assessment due date.",
            type: ReportType.EventOverdue,
        },
        {
            name: "Events Unassigned",
            description: "Number of days from the time the event was created until the time it was assigned.",
            type: ReportType.EventUnassigned,
        },
        {
            name: "Closed Events Comparison",
            description: "Number of events that have been auto-released or manually completed.",
            type: ReportType.ClosedEventsComparison,
        },
        {
            name: "Event Assessment Due",
            description: "Events that are due within the next 2 days.",
            type: ReportType.EventAssessmentDue,
        },
    ];

    const title = `Reports (${reportVariants.length})`;

    const renderPageHeader = (isFixed: boolean) => {
        return (
            <Grid container direction="row" className={withFixed(classes.headerContainer, isFixed)}>
                <LynxTypography variant={isFixed ? "h2" : "h1"} className={classes.title}>
                    {title}
                </LynxTypography>
                <Grid container item className={classes.infoBadge}>
                    <LynxIcon name="infoS" />
                    <LynxTypography variant="body-s">{reportsInfoText}</LynxTypography>
                </Grid>
            </Grid>
        );
    };

    const renderTableHeader = (isFixed: boolean) => (
        <TableHead>
            <TableRow className={classes.headerRow}>
                {tableHeaders.map((header) => (
                    <TableCell
                        className={withFixed(clsx(classes.cellBorderBottom, classes.headerCell), isFixed)}
                        key={header.title}
                        width={`${header.width}%`}
                    >
                        <LynxTypography color="neutral400" variant="h3">
                            {header.title}
                        </LynxTypography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <FixedHeaderRenderer level="second" topMargin={-32}>
                    {renderPageHeader(true)}
                </FixedHeaderRenderer>
                {renderPageHeader(false)}
                <FixedHeaderRenderer level="third" topMargin={-75}>
                    <div className={classes.fixedTableContainer}>
                        <Table className={classes.fixedTable}>{renderTableHeader(true)}</Table>
                    </div>
                </FixedHeaderRenderer>
                <Table className={classes.table}>
                    {renderTableHeader(false)}
                    <TableBody>
                        {reportVariants.map((report) => (
                            <TableRow className={classes.tableRow} key={report.name}>
                                <TableCell className={classes.cellBorderBottom} width="30%">
                                    <LynxTypography variant="body-l">{report.name}</LynxTypography>
                                </TableCell>
                                <TableCell className={classes.cellBorderBottom} width="50%">
                                    <LynxTypography variant="body">{report.description}</LynxTypography>
                                </TableCell>
                                <TableCell className={classes.cellBorderBottom} width="20%">
                                    <LynxButton
                                        leftIcon={<LynxIcon name="download" />}
                                        className={classes.actionButton}
                                        variant="tertiary"
                                        size="medium"
                                        onClick={() => {
                                            reportingStore.getReportCsv({
                                                customerId: identityStore.currentCustomer.id,
                                                type: report.type,
                                                currentTimezone,
                                            });
                                        }}
                                        loading={reportingStore.reportingProgressFlags[report.type]}
                                    >
                                        Download
                                    </LynxButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </main>
        </GeneralErrorComponentWrapper>
    );
});
