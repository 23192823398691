import { Grid, MenuItem } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { SectionCard } from "components/ReusableComponents/Cards/SectionCard";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LynxMultipleInputForm from "components/ReusableForms/LynxMultipleInputForm";
import LynxSelectForm from "components/ReusableForms/LynxSelectForm";
import { observer } from "mobx-react";
import { ChangeEvent } from "react";
import { useStore } from "store/StoreConfigs";
import { formikModelHelpers, formikModels, useLynxFormikContext, validationHelpers } from "validation";
import { stabilityFormStyles } from "../StabilityFormStyles";
import RangeFieldsArea from "./RangeFieldsArea";
import RepresentationFieldsArea from "./RepresentationFieldsArea";

const ProductFieldsArea = observer(() => {
    const formik = useLynxFormikContext<formikModels.StabilityFormFormikModel>();
    const { customerDataStore } = useStore();
    const classes = stabilityFormStyles();

    const handleChangeProductType = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        const storageTypeId = e.target.value as string;
        const storageType = customerDataStore.productSupportInfo.storageTypes.find((v) => v.id === storageTypeId)!;

        const formikRange = formikModelHelpers.convertToRangeFormikInputModel({
            lowerLimit: storageType.lowerLimit,
            upperLimit: storageType.upperLimit,
            lowerInclusive: true,
            upperInclusive: true,
        });

        formik.setValueOrEmptyStr("storageTypeId", storageTypeId, true);
        formik.setValueOrEmptyStr("lowerLimit", formikRange.lowerLimit, true);
        formik.setValueOrEmptyStr("upperLimit", formikRange.upperLimit, true);
        formik.setFieldValue("lowerInclusive", formikRange.lowerInclusive);
        formik.setFieldValue("upperInclusive", formikRange.upperInclusive);
        formik.setFieldValue("lowerOperator", formikRange.lowerOperator);
        formik.setFieldValue("upperOperator", formikRange.upperOperator);
    };

    return (
        <SectionCard>
            <LynxTypography variant={"h2"} className={classes.marginBottomLarge}>
                Product Information
            </LynxTypography>
            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="productBusinessKey"
                        label={<LabelWithRequiredSymbol label="Product ID (GMID)" />}
                        placeholder="Enter Product ID"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="productName"
                        label={<LabelWithRequiredSymbol label="Product Name" />}
                        placeholder="Enter Product Name"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="genericProductName"
                        label="Generic Product Name"
                        placeholder="Enter Generic Product Name"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxSelectForm
                        name="categoryId"
                        label="Product Category"
                        placeholder="Select Product Category"
                        showGeneratedDefaultOption
                    >
                        {customerDataStore.productSupportInfo.categories.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>
            </Grid>

            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxSelectForm
                        name="presentationId"
                        label="Presentation"
                        placeholder="Select Presentation"
                        showGeneratedDefaultOption
                    >
                        {customerDataStore.productSupportInfo.presentations.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>

                <Grid item xs={3}>
                    <LynxSelectForm
                        name="storageTypeId"
                        label={<LabelWithRequiredSymbol label="Product Type" />}
                        placeholder="Select Product Type"
                        onChange={(
                            e: ChangeEvent<{
                                name?: string | undefined;
                                value: unknown;
                            }>
                        ) => handleChangeProductType(e)}
                    >
                        {customerDataStore.productSupportInfo.storageTypes.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </LynxSelectForm>
                </Grid>

                <Grid item xs={6}>
                    <LynxTypography variant="body-s" color="neutral400" className={classes.label}>
                        {<LabelWithRequiredSymbol label="Product Storage Conditions" />}
                    </LynxTypography>
                    <RangeFieldsArea<formikModels.StabilityFormFormikModel>
                        disableWholeArea={!formik.values.storageTypeId}
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={4}>
                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="expiryPeriodInHours"
                        label="Expiry Period (hours)"
                        placeholder="Enter Expiry Period"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="freezeCycleLimit"
                        label="Freeze Cycle Count Allowed"
                        placeholder="Enter Freeze Cycle Count Allowed"
                    />
                </Grid>

                <Grid item xs={3}>
                    <LynxInputForm multiline name="productSpec" label="Product Spec" placeholder="Enter Product Spec" />
                </Grid>

                <Grid item xs={3}>
                    <LynxMultipleInputForm
                        name="SKUs"
                        label="SKUs"
                        placeholder="Enter SKUs"
                        validation={{
                            field: "SKUs",
                            isValid: validationHelpers.isValidSKUs,
                            setFieldError: formik.setFieldError,
                            errorMessage: "Must be a positive integer",
                        }}
                    />
                </Grid>

                {/* <Grid item xs={3}>
                    <LynxInputForm
                        multiline
                        name="product.heatCycleLimit"
                        label="Heat Cycle Count Allowed"
                        placeholder="Enter Heat Cycle Count Allowed"
                    />
                </Grid> */}
            </Grid>

            {/* <Grid container item spacing={4}>
                
            </Grid> */}

            <RepresentationFieldsArea />
        </SectionCard>
    );
});

export default ProductFieldsArea;
