import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperatureRange } from "helpers/rangeHelpers";
import { breakLines } from "helpers/stringHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { batchDistributionDiagramPageStyles } from "./BatchDistributionDiagramPageStyles";

interface Props {
    isExpandedTable: boolean;
}

export const BatchDistributionDiagramSummaryTable = observer(({ isExpandedTable }: Props) => {
    const { batchDistributionDiagramStore: store } = useStore();
    const classes = batchDistributionDiagramPageStyles();

    const excursionExists = store.diagramData.impacts.some((x) => x.excursionDuration > 0);
    const hasRsbAdjustment = store.diagramData.impacts.some((x) => x.totalRsbAdjustment);
    const totalRsbAdjustmentColSpan =
        !excursionExists && !hasRsbAdjustment ? 2 : !excursionExists || !hasRsbAdjustment ? 3 : 4;

    const tableHeaderCell = (title?: string, className?: string) => (
        <TableCell className={clsx(classes.overlayTableHeaderCell, className)}>
            <LynxTypography variant="body-xs" color="neutral400">
                {breakLines(title)}
            </LynxTypography>
        </TableCell>
    );

    return (
        <Table className={classes.overlayTable}>
            {!isExpandedTable ? (
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.overlayTableHeader}>
                                <div className={classes.headerBackground}>
                                    <LynxTypography variant="body-sbold-s">{`Flow: ${store.diagramData.flowName}`}</LynxTypography>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {tableHeaderCell("Temperature\nRange")}
                            {tableHeaderCell("Product Stability\nBudget")}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {store.diagramData.impacts
                            .slice()
                            .sort((a, b) => a.position - b.position)
                            .map((x) => (
                                <TableRow key={x.id}>
                                    <TableCell className={clsx(classes.overlayTableBodyCell)}>
                                        <LynxTypography>
                                            {formatTemperatureRange(
                                                x.lowerLimit,
                                                x.upperLimit,
                                                x.lowerInclusive,
                                                x.upperInclusive
                                            )}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell className={clsx(classes.overlayTableBodyCell)}>
                                        <LynxTypography>
                                            {formatDurationToString(x.baseline, x.baseline !== null)}
                                        </LynxTypography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </>
            ) : (
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={totalRsbAdjustmentColSpan}
                                className={clsx(classes.overlayTableHeader, classes.borderRight)}
                            >
                                <div className={classes.headerBackground}>
                                    <LynxTypography variant="body-sbold-s">{`Flow: ${store.diagramData.flowName}`}</LynxTypography>
                                </div>
                            </TableCell>
                            <TableCell colSpan={2} className={clsx(classes.overlayTableHeader, classes.borderRight)}>
                                <div className={classes.headerBackground}>
                                    <LynxTypography variant="body-sbold-s">{`Worst Case Calculations (${
                                        store.allEvents.length
                                    } event${store.allEvents.length > 1 ? "s" : ""})`}</LynxTypography>
                                </div>
                            </TableCell>
                            <TableCell colSpan={2} className={classes.overlayTableHeader}>
                                <div className={classes.headerBackground}>
                                    <LynxTypography variant="body-sbold-s">{`Enhanced Calculations (${
                                        store.relevantEvents.length
                                    } event${store.relevantEvents.length > 1 ? "s" : ""})`}</LynxTypography>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {excursionExists && tableHeaderCell("", classes.paddingZeroCell)}
                            {tableHeaderCell("Temperature\nRange", excursionExists ? classes.paddingZeroCell : "")}
                            {tableHeaderCell("Product Stability\nBudget", !hasRsbAdjustment ? classes.borderRight : "")}
                            {hasRsbAdjustment && tableHeaderCell("Total RSB\nAdjustment", classes.borderRight)}
                            {tableHeaderCell("Total Excursion\nHours")}
                            {tableHeaderCell("Product Remaining\nStability Budget", classes.borderRight)}
                            {tableHeaderCell("Total Excursion\nHours")}
                            {tableHeaderCell("Product Remaining\nStability Budget")}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {store.diagramData.impacts
                            .slice()
                            .sort((a, b) => a.position - b.position)
                            .map((x) => (
                                <TableRow key={x.id}>
                                    {excursionExists && (
                                        <TableCell
                                            className={clsx(classes.overlayTableBodyCell, classes.warningIconBodyCell)}
                                        >
                                            {x.excursionDuration > 0 && <LynxIcon name="triangleWarning" />}
                                        </TableCell>
                                    )}
                                    <TableCell className={classes.overlayTableBodyCell}>
                                        <LynxTypography>
                                            {formatTemperatureRange(
                                                x.lowerLimit,
                                                x.upperLimit,
                                                x.lowerInclusive,
                                                x.upperInclusive
                                            )}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell
                                        className={clsx(
                                            classes.overlayTableBodyCell,
                                            !hasRsbAdjustment ? classes.borderRight : ""
                                        )}
                                    >
                                        <LynxTypography>
                                            {formatDurationToString(x.baseline, x.baseline !== null)}
                                        </LynxTypography>
                                    </TableCell>
                                    {hasRsbAdjustment && (
                                        <TableCell className={clsx(classes.overlayTableBodyCell, classes.borderRight)}>
                                            <LynxTypography>
                                                {x.totalRsbAdjustment && formatDurationToString(x.totalRsbAdjustment)}
                                            </LynxTypography>
                                        </TableCell>
                                    )}
                                    <TableCell className={classes.overlayTableBodyCell}>
                                        <LynxTypography>
                                            {formatDurationToString(x.totalExcursionDuration)}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell className={clsx(classes.overlayTableBodyCell, classes.borderRight)}>
                                        <LynxTypography variant="body">
                                            {formatDurationToString(x.productRsb)}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell className={classes.overlayTableBodyCell}>
                                        <LynxTypography>
                                            {formatDurationToString(x.totalExcursionDurationNoBatchTracking)}
                                        </LynxTypography>
                                    </TableCell>
                                    <TableCell className={classes.overlayTableBodyCell}>
                                        <LynxTypography variant="body-sbold">
                                            {formatDurationToString(x.productRsbNoBatchTracking)}
                                        </LynxTypography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </>
            )}
        </Table>
    );
});
