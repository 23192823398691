import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import { AssessmentGeneralInformation } from "./AssessmentGeneralInformation/AssessmentGeneralInformation";
import { AssessmentProductInformation } from "./AssessmentProductInformation/AssessmentProductInformation";
import { AutomatedAssessment } from "./AutomatedAssessment/AutomatedAssessment";
import { ExcursionReview } from "./ExcursionReview/ExcursionReview";
import { ProductDecision } from "./ProductDecision/ProductDecision";
import { AssessmentTemperatureRanges } from "./AssessmentTemperatureRanges/AssessmentTemperatureRanges";
import { observer } from "mobx-react";
import { assessmentDetailsStyles } from "./AssessmentDetailsStyles";
import { AssessmentDetailsProps } from "./AssessmentDetailsProps";
import { BatchHistory } from "./BatchHistory/BatchHistory";
import { useStore } from "store/StoreConfigs";
import { GetProductFlowsRequest } from "api/models/events/eventsApi";
import { AssessmentStatus } from "models/thorEvents/eventModels";

export const AssessmentDetails = observer((props: AssessmentDetailsProps) => {
    const { assessment, isSelected } = props;
    const classes = assessmentDetailsStyles();
    const { thorEventViewStore, identityStore } = useStore();

    useEffect(() => {
        if (!assessment.flows?.length && isSelected && assessment.stabilityFormId) {
            const request: GetProductFlowsRequest = {
                customerId: identityStore.currentCustomer.id,
                eventId: thorEventViewStore.eventDetails.id,
                stabilityFormId: assessment.stabilityFormId,
            };

            thorEventViewStore.getProductFlows(request);
        }
    }, [isSelected, assessment.flows?.length]);

    useEffect(() => {
        if (thorEventViewStore.selectedAssessment === props.assessment.id) {
            const isRelevantOnly = !identityStore.customerConfiguration.batchTrackingEnabled
                ? false
                : thorEventViewStore.batchHistoryRelevantEventsTabSelected;

            thorEventViewStore.getBatchHistory({
                eventId: thorEventViewStore.eventDetails.id,
                batchId: assessment.batchId,
                relevantEventsOnly: isRelevantOnly,
                customerId: identityStore.currentCustomer.id,
                pageNumber: thorEventViewStore.batchHistoryCurrentPage,
                pageSize: thorEventViewStore.batchHistoryPageSize,
            });
        }
    }, [
        thorEventViewStore.batchHistoryCurrentPage,
        thorEventViewStore.batchHistoryPageSize,
        thorEventViewStore.batchHistoryRelevantEventsTabSelected,
        thorEventViewStore.selectedAssessment,
    ]);

    return (
        <div className={classes.root}>
            <AssessmentGeneralInformation assessment={assessment} flows={assessment.flows ?? []} />
            <AssessmentProductInformation assessment={assessment} />
            <Grid container className={classes.assessmentDecisionSection} direction="row" wrap="nowrap">
                <Grid item xs={5} className={classes.assessmentDecisionLeftSide}>
                    <AutomatedAssessment
                        decision={assessment.recommendedDecision}
                        messages={assessment.recommendationMessages}
                    />
                    <ExcursionReview assessment={assessment} />
                    <ProductDecision assessment={assessment} />
                </Grid>
                <Grid item xs={7}>
                    <AssessmentTemperatureRanges ranges={assessment.impacts} />
                </Grid>
            </Grid>

            <BatchHistory
                historicalEvents={assessment.historicalEvents ?? []}
                assessmentId={assessment.id}
                assessmentCompleted={assessment.status === AssessmentStatus.EvaluationCompleted}
                assessmentCompletedDate={assessment.completedDate}
            />
        </div>
    );
});
